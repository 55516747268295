.TransitWrapper{
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(180deg, #000000 55%, rgb(135, 135, 135));
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
}

.TransitTop{
    width: 100vw;
    height: 20%;
}

.TransitMiddle{
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.TransitBottom{
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;

}

.TransitCenterBox{

    width: 45%;
    height: 50%;
    display: flex;
    flex-direction: column;
}

.TransitCenterBox_Mobile{
    width: 80%;
    height: 30%;
    display: flex;
    flex-direction: column;
}

.TransitCenterBox_Ipad{
    width: 55%;
    height: 30%;
    display: flex;
    flex-direction: column;
}

.TransitCenterBoxTop{
    height: 70%;
    display: flex;
    flex-direction: row;
}

.TransitCenterBoxTop_Mobile{
    height: 60%;
    display: flex;
    flex-direction: row;
}


.TransitCenterBoxBottom{
    height: 30%;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.TransitBoxTitel{
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
}

.TransitBoxTitel_Mobile{
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
}


.TransitBoxLogoArea{
    width: 10vw;
    height: 10vw;
}


.TransitBoxLogoArea_Mobile{
    width: 20vw;
    height: 20vw;
}

.TransitBoxTitelRow1{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
}

.TransitBoxTitelRow2{
    width: 100%;
    height: auto;
}

.TransitBoxTitelElementColored{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 250%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TransitBoxTitelElementColored_Mobile{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 140%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TransitBoxTitelElement{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #b4b4b4 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 250%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TransitBoxTitelElement_Mobile{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #b4b4b4 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 140%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TransitBoxTitelElement2{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #b4b4b4 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 200%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TransitBoxTitelElement2_Mobile{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #b4b4b4 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 100%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TransitCenterBoxLoader{
    text-align: center;
    color: #b4b4b4;
    font-size: 150%;
    font-weight: 500;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: flex;
    flex-direction: row;
}

.TransitCenterBoxLoader_Mobile{
    text-align: center;
    color: #b4b4b4;
    font-size: 90%;
    font-weight: 500;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    display: flex;
    flex-direction: row;
}

.Wait1{
    text-align: center;
    font-weight: 500;
    font-family: Verdana, Geneva, Tahoma, sans-serif; 
    animation: WaitLoader1 3s infinite;
}

.Wait2{
    text-align: center;
    font-weight: 500;
    font-family: Verdana, Geneva, Tahoma, sans-serif; 
    animation: WaitLoader2 3s infinite;
}

.Wait3{
    text-align: center;
    font-weight: 500;
    font-family: Verdana, Geneva, Tahoma, sans-serif; 
    animation: WaitLoader3 3s infinite;
}

.nextLinkButton{
    padding-top: 1%;
    padding-bottom: 1%;
    width: 15%;
    text-align: center;
    border-radius: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif; 
    font-size: 150%;
    background-color: #e1743c;
    box-shadow: 1px 1px 5px 1px rgb(78, 78, 78);
}

.nextLinkButton_Mobile{
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 4%;
    padding-right: 4%;
    width: 30%;
    text-align: center;
    border-radius: 25px;
    font-family: Verdana, Geneva, Tahoma, sans-serif; 
    font-size: 120%;
    background-color: #e1743c;
    box-shadow: 1px 1px 5px 1px rgb(78, 78, 78);
}

.nextLinkButton_gone{
    position: absolute;
    margin-left: -5000vw;
}

.KursIdFinder{
    background-color: #e1743c;
    box-shadow: 1px 1px 5px 1px rgb(78, 78, 78);
    position: absolute;
    width: 40%;
    height: 20%;
    Left: 30%;
    Top:40%;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.KursIdFinder_success{
    background-color: #14a94d;
    box-shadow: 1px 1px 5px 1px rgb(78, 78, 78);
    position: absolute;
    width: 40%;
    height: 20%;
    Left: 30%;
    Top:40%;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.KursIdFinder_Mobile{
    background-color: #e1743c;
    box-shadow: 1px 1px 5px 1px rgb(78, 78, 78);
    position: absolute;
    width: 80%;
    height: 20%;
    Left: 10%;
    Top:40%;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.KursIdFinder_Mobile_success{
    background-color: #14a94d;
    box-shadow: 1px 1px 5px 1px rgb(78, 78, 78);
    position: absolute;
    width: 80%;
    height: 20%;
    Left: 10%;
    Top:40%;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.KursIdFinder_gone{
    position: absolute;
    margin-left: -2000vw;
}

.KursIdFinderTitel{
    width: 100%;
    height: 20%;
    text-align: center;
    margin-top: 2%;
    font-size: 190%;
    font-weight: 800;
}

.KursIdFinderTitel_Mobile{
    width: 100%;
    height: 20%;
    text-align: center;
    margin-top: 2%;
    font-size: 140%;
    font-weight: 800;
}

.KursIdFinderRow{
    width: 80%;
    margin-left: 10%;
    margin-top: 0%;
    height: 60%;
    display: flex;
    flex-direction: row;
}

.KursIdFinderSearchArea{
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.KursIdFinderField{
    font-size: 190%;
    text-align: center;
    width: 90%;
}

.KursIdFinderField_Mobile{
    font-size: 130%;
    text-align: center;
    width: 80%;
}

.KursIdFinderField_gone{
    position: absolute;
    margin-left: -2000vw;
}

.KursIdFinderButtonField{
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.KursIdFinderButton1{
    background-color: #b4b4b4;
    border-radius: 15px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 150%;
    font-weight: 500;
    box-shadow: 1px 1px 5px 1px rgb(78, 78, 78);
}

.KursIdFinderButton1:hover{
    cursor: pointer;
}

.KursIdFinderButton1_Mobile{
    background-color: #b4b4b4;
    border-radius: 15px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 110%;
    font-weight: 500;
    box-shadow: 1px 1px 5px 1px rgb(78, 78, 78);
}

.KursIdFinderButton1_gone{
    position: absolute;
    margin-left: -2000vw;
}

@keyframes WaitLoader1 {
    0%{color: #b4b4b400;}   
    20%{color: #b4b4b400;}  
    100%{color: #b4b4b4;}   
}

@keyframes WaitLoader2 {
    0%{color: #b4b4b400;}   
    40%{color: #b4b4b400;}  
    100%{color: #b4b4b4;}   
}

@keyframes WaitLoader3 {
    0%{color: #b4b4b400;}   
    60%{color: #b4b4b400;}  
    100%{color: #b4b4b4;}   
}