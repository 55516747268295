.KursblockWrapper{
    width: 90%;
    height: auto;
    background-color: rgb(176, 176, 176);
    margin-left: 5%;
    margin-top: 6%;
    margin-bottom: 5%;
}

.KursblockWrapper_Mobile{
    width: 100%;
    height: auto;
    background-color: rgb(176, 176, 176);
    margin-left: 5%;
    margin-top: 6%;
    margin-bottom: 5%;
}

.KursTable{
    width: 100%;
}

.KursTable_Mobile{
    width: 100%;
}

.TableHeader{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 110%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.TableHeader_Mobile{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 70%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.TableText{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.TableText_Mobile{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 60%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.TableText0{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: 1000;
}



.TableText0_Mobile{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 60%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: 1000;
}

.TableText2{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.TableText2_Mobile{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 60%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.TableText2_full{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
    color: red;
    font-weight: 1000;
}

.TableText2_full_Mobile{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 60%;
    padding-top: 1%;
    padding-bottom: 1%;
    color: red;
    font-weight: 1000;
}

.TableText3{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 90%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.TableText3_Mobile{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 70%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: 800;
}

.AnmeldeLink{
    border: 1px solid #c2602f;
    border-radius: 10px;
    background-color: #e1743c;
    padding-top: 1%;
    padding-bottom: 1%;
}

.AnmeldeLink:hover{
    background-color: #edad8d;
    transition: 1s;
    cursor: pointer;
}

.AnmeldeLink_gone{
   position: absolute;
   margin-left: -500vw;
}