.ModulBody{
    margin-top: 2%;
    margin-left: 6%;
    height: 50vh;
    width: 88%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.ModulBody_Mobile{
    margin-top: 2%;
    margin-left: 6%;
    width: 88%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: auto;
}

.FlowChartArea{
    width: 98%;
    height: 35vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.FlowChartArea_Mobile{
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.ModulBlockOnline{
    
    background-color:  rgba(250, 250, 250, 0.689);
    width: 16.6%;
    display: flex;
    flex-direction:column;
    border-radius: 10px;
}

.ModulBlockOnline_Mobile{
    border: 2px dotted rgb(222, 222, 222);
    background-color:  rgba(241, 239, 239, 0.566);
    width: 100%;
    height: 20vh;
    margin-bottom: 7%;
    display: flex;
    flex-direction:row;
    border-radius: 10px;
}

.ModulBlockOffline{
    background-color: #d9ac95 ;
    width: 16.6%;
    display: flex;
    flex-direction:column;
    border-radius: 10px;
}

.ModulBlockOffline_Mobile{
    background-color: #d9ac95 ;
    width: 100%;
    height: 20vh;
    margin-bottom: 7%;
    display: flex;
    flex-direction:row;
    border-radius: 10px;
}

.ModulBlockTextArea{
    width: 100%;
    height: 75%;
}

.ModulBlockLink{
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.ModulBlockTextArea_Mobile{
    width: 100%;
    height: 100%;
    width: 75%;
}

.ModulBlockLink_Mobile{
    width: 100%;
    height: 100%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.ModulBlockLinkLink{
    border: 1px solid black;
    border-radius: 15px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 120%;
    font-weight: 600;
}

.ModulBlockLinkLink:hover{
   cursor: pointer;
   background-color: #e1743c;
   transition: 1s;
}

.ModulBlockLinkLink_Mobile{
    border-radius: 15px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 250%;
    background-color: #e1743c;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    font-weight: 600;
}

.ModulBlockLinkLink_Mobile:hover{
    cursor: pointer;
    background-color: #e1743c;
    transition: 1s;
 }

 .ModulBlockTitel{
    width: 100%;
    font-size: 150%;
    margin-top: 2%;
    font-weight: 500;
    text-align: center;
 }

 .ModulBlockTitel_Mobile{
    width: 100%;
    font-size: 110%;
    margin-top: 2%;
    font-weight: 500;
    text-align: center;
    
 }

 .ModulBlockText{
    height: 100%;
    width: 95%;
    font-size: 110%;
    display: flex;
    align-items: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
 }

 .ModulBlockText_Mobile{
    height: 95%;
    width: 95%;
    font-size: 70%;
    display: flex;
    align-items: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
 }

 .ModulBlockText_Medium{
    height: 95%;
    width: 95%;
    font-size: 85%;
    display: flex;
    align-items: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
 }