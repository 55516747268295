.NavImage_Philipp{
    background-image: url('../../material/Smile.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Philipp:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_Philipp_active{
    background-image: url('../../material/Smile.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .NavImage_Regulation{
    background-image: url('../../material/Gears.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Regulation:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_Regulation_active{
    background-image: url('../../material/Gears.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .PhilippArea{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 95%;
    display: flex;
    flex-direction: column;
    
}

.PhilippArea_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 10%;
    height: 82%;
    display: flex;
    flex-direction: column;
    
}

.PhilippTitel{
    text-align: center;
    font-size: 200%;
    font-weight: 800;
    margin-top: -2%;
}

.PhilippTitel_Mobile{
    text-align: center;
    font-size: 150%;
    font-weight: 800;
}

.PhilippModelArea{
    width: 100%;
    height: 85%;
    margin-top: 1%;
}

.PhilippModelArea_Mobile{
    width: 100%;
    height: 91%;
    margin-top: 6%;
}

.FeedbackTrichter{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.FeedbackTrichter_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 10%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.FeedbackTrichterTitel{
    text-align: center;
    font-size: 180%;
    font-weight: 800;
    margin-top: -2%;
}

.FeedbackTrichterTitel_Mobile{
    text-align: center;
    font-size: 150%;
    font-weight: 800;
}

.FeedbackTrichterArea{
    width: 100%;
    height: 85%;
    margin-top: 1%;
    
}

.FeedbackTrichterArea_Mobile{
    width: 100%;
    height: 91%;
    margin-top: 6%;
}

.Selbstregulation{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Selbstregulation_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 10%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.SelbstregulationTitel{
    text-align: center;
    font-size: 180%;
    font-weight: 800;
    margin-top: -2%;
}

.SelbstregulationTitel_Mobile{
    text-align: center;
    font-size: 150%;
    font-weight: 800;
}