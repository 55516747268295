.LoaderWrapper
{
    display: flex;
    width: 100%;
    height: 100%;
}

.LoaderRing_Loading{
    border: 12px dashed #e1743c;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    animation: LoaderRotate 4s infinite;
}

.LoaderRing_Loading_Mobile{
    border: 8px dotted #e1743c;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    animation: LoaderRotate 4s infinite;
}

.LoaderRing_Loading_Ipad{
    border: 10px dotted #e1743c;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    animation: LoaderRotate 4s infinite;
}

.LoaderRing_Mobile_Finished{
    border: 8px dotted #1fa064;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    transform: rotate(-19deg);
}

.LoaderRing_Finished{
    border: 12px dashed #1fa064;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    transform: rotate(-19deg);
}

.LoaderRing_gone{
    position: absolute;
    margin-left: -2000vw;
}

.LoaderBreakY{
    height: 100%;
    width: 100%;
    border: 2px solid #e1743c;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.LoaderBreakY_Mobile{
    height: 100%;
    width: 100%;
    border: 1px solid #e1743c;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.LoaderBreakY_Ipad{
    height: 100%;
    width: 100%;
    border: 1px solid #e1743c;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.LoaderBreakX{
    height: 85%;
    width: 85%;
    border-radius: 100%;
    border: 12px dashed #dfdfdf;
    animation: LoaderRotate 5s infinite reverse;
}

.LoaderBreakX_Finished{
    height: 85%;
    width: 85%;
    border-radius: 100%;
    border: 12px dashed #1fa064;
    animation: LoaderRotate 5s infinite reverse;
}

.LoaderBreakX_Mobile{
    height: 85%;
    width: 85%;
    border-radius: 100%;
    border: 5px dotted  #dfdfdf;
    animation: LoaderRotate 5s infinite reverse;
}

.LoaderBreakX_Ipad{
    height: 85%;
    width: 85%;
    border-radius: 100%;
    border: 5px dotted  #dfdfdf;
    animation: LoaderRotate 5s infinite reverse;
}

.LoaderBreakX_Mobile_Finished{
    height: 85%;
    width: 85%;
    border-radius: 100%;
    border: 5px dotted #1fa064;
    animation: LoaderRotate 5s infinite reverse;
}

.Loader_Error{
    color: black;
    font-size: 400%;
    font-weight: 1000;
}

.Loader_Error_gone{
    position: absolute;
    margin-left: -2000vw;
}

@keyframes LoaderRotate {
    0% {transform: rotate(0deg);}   
    50% {transform: rotate(180deg);} 
    100% {transform: rotate(360deg);}
}
