.M3PrinterWrapper{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.M3PrinterSuperTitel{
    width: 100%;
    text-align: center;
    margin-top: 2%;
}

.M3Druckbereich{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.M3Druck_Titel{
    width: 100%;
    text-align: center;
    margin-top: 5%;
    font-size: 200%;
    font-weight: 800;
}

.M3Druck_Einleitung{
    width: 85%;
    margin-top: 2%;
    font-size: 120%;
}

.Printer_LernerTextArea{
    width: 90%;
    height: auto;
    margin-top: 2%;
    page-break-after: always;
}

.ShowFynn{
    width: 100%;
    height: auto;
}

.ShowMia{
    width: 100%;
    height: auto;
}

.Fynn_gone{
    position: absolute;
    margin-left: -2000vw;
}

.Mia_gone{
    position: absolute;
    margin-left: -2000vw;
}

.Printer_ErgebnisArea{
    width: 90%;
    height: auto;
    margin-top: 2%;
}