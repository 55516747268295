.M3HW_A_Wrapper{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-x: hidden;
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
    display: flex;
    flex-direction: column;
    background: linear-gradient(-45deg, #5d5d5d, rgb(197, 197, 197)5%, rgb(196, 196, 196) 10%, rgb(215, 215, 215) 20%, rgb(241, 241, 241) 50%, rgb(215, 215, 215) 80%, rgb(215, 215, 215) 90%, rgb(186, 186, 186)95%, #434343);
}

.M3HW_A_Titel{
    width: 100%;
    font-size: 250%;
    text-align: center;
    margin-top: 2%;
    font-weight: 500;
}

.M3HW_A_Titel_Mobile{
    width: 100%;
    font-size: 150%;
    text-align: center;
    margin-top: 5%;
    font-weight: 500;
}

.H3HW_A_Box{
    width: 80%;
    margin-left: 10%;
    margin-top: 3%;
    display: flex;
    flex-direction: row;
}

.H3HW_A_Box_Mobile{
    width: 90%;
    margin-left: 5%;
    margin-top: 7%;
    display: flex;
    flex-direction: column;
}

.H3HW_A_Box_Label{
    width: 25%;
    font-size: 130%;
    font-weight: 500;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.H3HW_A_Box_Field{
    width: 75%;
    font-size: 110%;
    font-weight: 500;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.H3HW_A_Box_Field2{
    width: 75%;
    font-size: 130%;
    font-weight: 500;
    height: 50vh;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.H3HW_A_Box_Field2_Mobile{
    width: 100%;
    font-size: 100%;
    font-weight: 500;
    height: 35vh;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.H3HW_A_Box_Label_Mobile{
    width: 100%;
    font-size: 130%;
    font-weight: 500;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.H3HW_A_Box_Field_Mobile{
    width: 100%;
    font-size: 100%;
    font-weight: 500;
    margin-top: 2%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.M3HW_A_But_Area{

    width: 100%;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.M3HW_A_But{
    background-color: #e1743c;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 15px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 120%;
    font-weight: 600;
    box-shadow: 1px 1px 5px 2px rgb(11, 11, 11);
}

.M3HW_A_But_gone{
    position: absolute;
    margin-left: -2000vw;
}

.M3HW_A_But_Mobile{
    background-color: #e1743c;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 6%;
    padding-right: 6%;
    border-radius: 15px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 100%;
    font-weight: 500;
    box-shadow: 1px 1px 5px 2px rgb(11, 11, 11);
}

.M3HW_A_Student_Selector{
    width: 75%;
    height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.M3HW_A_Student_Selector_Mobile{
    width: 100%;
    height: 8vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1%;
}

.M3HW_A_Student{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    width: 18%;
    border: 1px solid rgb(160, 160, 160);
    border-radius: 15px;
    padding-top: 1%;
    font-size: 110%;
}

.M3HW_A_Student:hover{
    cursor: pointer;
}

.M3HW_A_Student_Mobile{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    width: 23%;
    border: 1px solid rgb(160, 160, 160);
    border-radius: 15px;
    padding-top: 3%;
    font-size: 75%;
}

.M3HW_A_Student_Mobile:hover{
   cursor: pointer;
}

.M3HW_A_Student_active{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    width: 18%;
    border: 1px solid rgb(160, 160, 160);
    border-radius: 15px;
    padding-top: 1%;
    font-size: 110%;
    background-color: #e1743c;
}

.M3HW_A_Student_Mobile_active{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    width: 23%;
    border: 1px solid rgb(160, 160, 160);
    border-radius: 15px;
    padding-top: 3%;
    font-size: 75%;
    background-color: #e1743c;
}

.M3HW_LoaderArea{
    width: 5vw;
    height: 5vw;
    margin-top: -3%;
}

.M3HW_LoaderArea_Mobile{
    width: 15vw;
    height: 15vw;
    margin-top: -3%;
}

.M3HW_LoaderArea_gone{
    position: absolute;
    margin-left: -2000vw;
}

.M3HW_Messenger{
    position: absolute;
    Top: 45%;
    Left: 20%;
    background-color: white;
    width: 50%;
    height: 10vh;
    border: 2px solid #e1743c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 130%;
}

.M3HW_Messenger_gone{
    position: absolute;
    margin-left: -2000vw;
}

.M3HW_MessengerButton{
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 25px;
    background-color: #e1743c;
    margin-top: 2%;
}

.M3HW_MessengerButton:hover{
    cursor: pointer;
}