.ModulubersichtWrapper2{
    position: absolute;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: black;
}

.Modulubersicht2_Body{
    width: 100%;
    height: 100vh;
    display: flex;
}


.Modulubersicht2_Body_Mobile{
    width: 100%;
    height: auto;
    display: flex;
}


.Modulfacher{

    width: 90%;
    margin-left: 5%;
    margin-top: 6%;
    height: 84%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Modulfacher_Mobile{

    width: 90%;
    margin-left: 5%;
    margin-top: 25%;
    height: 84%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ModulFacherKomponent{
    background-color: rgba(255, 255, 255, 0.541);
    width: 28%;
    height: 100%;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    transition: 1s;
}

.ModulFacherKomponent_Mobile{
    background-color: rgba(255, 255, 255, 0.541);
    width: 100%;
    height: 100%;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    margin-bottom: 8%;
}

.ModulFacherKomponent:hover{
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.742);
    width: 28%;
    height: 100%;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    transition: 1s;
}

.ModulFacherKomponent_Logo{
    width: 80%;
    height: 40%;
    margin-left: 10%;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.ModulFacherKomponent_Logo_Mobile{
    width: 80%;
    height: 30vh;
    margin-left: 10%;
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.Modul1_Logo{
    background-image: url('../material/Modul1_Logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 85%;
    height: 100%;
    overflow: hidden;
}

.Modul2_Logo{
    background-image: url('../material/Modul2_Logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 85%;
    height: 100%;
    overflow: hidden;
}

.Modul3_Logo{
    background-image: url('../material/Modul3_Logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 85%;
    height: 100%;
    overflow: hidden;
}



.ModulFacherKomponent_Titel{
    text-align: center;
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
    font-size: 230%;
    font-weight: 800;
}

.ModulFacherKomponent_Titel_Medium{
    text-align: center;
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
    font-size: 150%;
    font-weight: 800;
}

.ModulFacherKomponent_Titel_Mobile{
    text-align: center;
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
    font-size: 160%;
    font-weight: 800;
}

.ModulFacherKomponent_Titel_4K{
    text-align: center;
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
    font-size: 260%;
    font-weight: 800;
}

.ModulFacherKomponent_Text{
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
    font-size: 150%;
    font-weight: 500;
}

.ModulFacherKomponent_Text_Medium{
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
    font-size: 120%;
    font-weight: 500;
}

.ModulFacherKomponent_Text_Mobile{
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
    font-size: 120%;
    font-weight: 500;
}

.ModulFacherKomponent_Text_4K{
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
    font-size: 180%;
    font-weight: 500;
}