.Ergebnis_Wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.Ergebnis_Wrapper_Mobile{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.ErgebnisBox{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-items: center;
    align-items: center;
    overflow-y: scroll;
}

.ErgebnisBox_Mobile{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-items: center;
    align-items: center;
    overflow-y: scroll;
}

.ErgebnisBoxTitel{
    width: 100%;
    height: 10%;
    text-align: center;
    font-size: 150%;
    margin-top: 2%;
    font-weight: 500;
}

.ErgebnisBoxTitel_Mobile{
    width: 100%;
    height: 12%;
    text-align: center;
    font-size: 90%;
    margin-top: 2%;
    font-weight: 500;
}

.ErgebnisseBoxDataArea{
    width: 95%;
    height: 80%;
    display: flex;
    flex-direction: column;
}

.ErgebnisseBoxDataArea_Mobile{
    width: 95%;
    height: 80%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
}


