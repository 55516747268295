.HomeWorkShowerWrapper{
    width: 90%;
    height: 95%;
    background-color: rgb(239, 240, 230);
    box-shadow: 1px 1px 5px 1px rgb(87, 87, 87);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    font-size: 130%;
    padding-top: 1%;
    margin-left: 5%;
}