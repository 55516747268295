.NavImage_ChatGPT{
    background-image: url('../../material/ChatGPT.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_ChatGPT:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_ChatGPT_active{
    background-image: url('../../material/ChatGPT.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .NavImage_OwnGPT{
    background-image: url('../../material/OwnGPT.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_OwnGPT:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_OwnGPT_active{
    background-image: url('../../material/OwnGPT.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

  .SchreibproduktFeedback{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 95%;
    display: flex;
    flex-direction: column;
}

.SchreibproduktFeedback_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 10%;
    height: 82%;
    display: flex;
    flex-direction: column;
    
}

.SchreibproduktFeedbackTitel{
    text-align: center;
    font-size: 200%;
    font-weight: 800;
    margin-top: -2%;
}

.SchreibproduktFeedbackTitel_Mobile{
    text-align: center;
    font-size: 150%;
    font-weight: 800;
}

.SchreibproduktFeedbackMain{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.SchreibproduktFeedbackMain2{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.SchreibproduktFeedbackMain_Mobile{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.SchreibproduktFeedbackMain2_Mobile{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.SchreibproduktFeedbackMainBox{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  
}

.SchreibproduktFeedbackMainBox_Mobile{
    width: 100%;
    height: 45%;
    margin-top: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}




.AiExplainArea{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 95%;
    display: flex;
    flex-direction: column;
}

.AiExplainArea_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 10%;
    height: 82%;
    display: flex;
    flex-direction: column;
}

.AiExplainAreaTitel{
    text-align: center;
    font-size: 200%;
    font-weight: 800;
    margin-top: -2%;
}

.AiExplainAreaTitel_4K{
    text-align: center;
    font-size: 250%;
    font-weight: 800;
    margin-top: -2%;
}

.AiExplainAreaTitel_Mobile{
    text-align: center;
    font-size: 150%;
    font-weight: 800;
}

.AiExplainAreaBody{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.AiExplainAreaBody_4K{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    font-size: 150%;
}

.AiExplainAreaBody_Mobile{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

}

.ChatGPTShareBox{
    border: 1px solid black;
    margin-top: 5%;
    height: 45%;
    margin-bottom: 5%;
    display: flex;
    flex-direction: column;
}

.ChatGPTShareBox{
    border: 1px solid black;
    margin-top: 1%;
    height: 40%;
    margin-bottom: 0%;
    display: flex;
    flex-direction: column;
}

.ChatGPTShareBoxTitel{
    font-size: 150%;
    font-weight: 800;
    margin-left: 2%;
    margin-top: 1%;
}

.ChatGPT_Input_Row{
    height: 50%;
    display: flex;
    flex-direction: row;
}

.ChatGPTInput_Field{
    font-size: 150%;
    font-weight: 500;
    margin-left: 2%;
    margin-top: 1%;
    width: 80%;
}

.ChatGPTInput_send{
    text-align: center;
    width: 15%;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 3%;
    padding-right: 3%;
    border-radius: 25px;
    background-color: #e1743c;
    font-size: 150%;
    margin-left: 2%;
    font-weight: 500;
}

.ChatGPTInput_send_gone{
    position:absolute;
    margin-left: -5000vw;
}

.ChatGPTInput_send:hover{
    cursor: pointer;
    background-color: #ff5500;
    font-size: 150%;
    margin-left: 2%;
    font-weight: 500;
    transition: 1s;
}