
*{
    box-sizing: border-box;
}
.M3CK1S_Wrapper{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-x: hidden;
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
    background: linear-gradient(-45deg, #5d5d5d, rgb(197, 197, 197)5%, rgb(196, 196, 196) 10%, rgb(215, 215, 215) 20%, rgb(241, 241, 241) 50%, rgb(215, 215, 215) 80%, rgb(215, 215, 215) 90%, rgb(186, 186, 186)95%, #434343);
    
}

.M3CK1S_Top{
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.M3CK1S_Middle{
    height: 85%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    overflow-x: hidden;
}

.M3CK1S_Content_Area{
    width: 90%;
    margin-left: 5%;
    margin-top: 1%;
    height: 95%;
    transition: 1s;
    overflow: hidden;
    overflow-x: hidden;
}

.M3CK1S_Content_Area_leftOff{
    position: fixed;
    width: 90%;
    margin-left: 5%;
    margin-top: 1%;
    height: 55%;
    margin-left: -150vw;
    transition: 1s;
}

.M3CK1S_Content_Area_rightOff{
    position: fixed;
    width: 90%;
    margin-left: 5%;
    margin-top: 1%;
    height: 85%;
    margin-left: 150vw;
    transition: 1s;
}

.M3CK1S_ContentArea_Outer{
    background-color: rgb(244, 244, 244);
    width: 95%;
    height: 100%;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.M3CK1S_ContentArea_Outer2{
    position: relative;
    
    width: 99.2%;
    height: 99%;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    

}

.M3CK1S_ContentArea_Content{
    position: relative;
    width: 98%;
    height: 98%;
    border-radius: 100px;

}

.M3CK1S_ContentArea_MarkerThing{
    width: 35%;
    height: 2%;
    position: absolute;
    Top: -1.5%;
    margin-left: -12%;
    display: flex;
}

.M3CK1S_ContentArea_MarkerThing_Marker{

    width: 40%;
    height: 100%;
    animation: Marker1Slide 50s infinite;
}


.M3CK1S_ContentArea_MarkerThing2{
    width: 35%;
    height: 2%;
    position: absolute;
    Bottom: -1.5%;
    margin-left: 12%;
    display: flex;
}

.M3CK1S_ContentArea_MarkerThing_Marker2{

    width: 40%;
    height: 100%;
    animation: Marker1Slide 50s infinite;
}

.M3CK1S_ContentArea_MarkerThing3{
    width: 2%;
    height: 25%;
    position: absolute;
    Left: -13%;
    margin-left: 12%;
    display: flex;
}

.M3CK1S_ContentArea_MarkerThing_Marker3{
    width: 100%;
    height: 50%;
    margin-top: 200%;
    animation: Marker3Slide 20s infinite;
}

.M3CK1S_NextButton_first{
    
    position: absolute;
    right: 2%;
    margin-top: 10%;
    font-size: 1000%;
    animation: ButtonPulse 1s infinite;
}

.M3CK1S_NextButton_first:hover{
    cursor: pointer;
}

.M3CK1S_NextButton{
    
    position: absolute;
    right: 2%;
    margin-top: 10%;
    font-size: 1000%;
    color: #e1743c;
}

.M3CK1S_NextButton:hover{
   cursor: pointer;
}

.M3CK1S_NextButton_Mobile_first{
    
    position: absolute;
    right: 2%;
    margin-top: 60%;
    font-size: 300%;
    animation: ButtonPulse 1s infinite;
}

.M3CK1S_NextButton_Mobile{
    
    position: absolute;
    right: 2%;
    margin-top: 60%;
    font-size: 300%;
    color: #e1743c;
}


@keyframes Marker1Slide {
    0% {margin-left: 0%;}
    50% {margin-left: 60%;}    
}

@keyframes Marker3Slide {
    0% {margin-top: 0%;}
    50% {margin-top: 200%;}    
    100% {margin-top: 0%;}
}

@keyframes ButtonPulse {
    0% {color: #e1743c;}
    50% {color: #c9bab2;}    
    100% {color: #e1743c;}
}


.BauermannPohl{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 95%;
    display: flex;
    flex-direction: column;
}

.BauermannPohl_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 10%;
    height: 82%;
    display: flex;
    flex-direction: column;
}

.BauermannPohlModelArea{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.BauermannPohlModelArea_Mobile{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}


.BauermannPohlTitel{
    text-align: center;
    font-size: 200%;
    font-weight: 800;
    margin-top: -2%;
}

.BauermannPohlTitel_Mobile{
    text-align: center;
    font-size: 150%;
    font-weight: 800;
}

.Schreibaufgabe1{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 95%;
    display: flex;
    flex-direction: row;
    
}

.Schreibaufgabe1_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 20%;
    height: 75%;
    display: flex;
    flex-direction: column;
    
}

.Sch1_SpiegelTextArea{
    
    width: 40%;
    display: flex;
    flex-direction: column;
    height: 95%;
}

.Sch1_SpiegelTextArea_Medium{
    
    width: 40%;
    display: flex;
    flex-direction: column;
    height: 95%;
    overflow-y: scroll;
}

.Sch1_SpiegelTextArea_Mobile{
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.Schi1_SpiegelTextTitel{
    text-align: center;
    font-size: 130%;
    font-weight: 800;
    font-family: 'Times New Roman', Times, serif;
}

.Schi1_SpiegelTextTitel_Mobile{
    text-align: center;
    font-size: 110%;
    font-weight: 800;
    font-family: 'Times New Roman', Times, serif;
}

.Schi1_SpiegelTextImage1{
    margin-top: 1%;
    margin-left: 5%;
    width: 90%;
    background-image: url('../../material/Spiegel_Bild.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 22%;
}

.Schi1_SpiegelTextImage1_Mobile{
    margin-top: 1%;
    margin-left: 5%;
    width: 90%;
    background-image: url('../../material/Spiegel_Bild.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 22%;
}

.Schi1_SpiegelTextArticle{
    width: 90%;
    margin-left: 5%;
    font-size: 75%;
    font-family: 'Times New Roman', Times, serif;
}

.Schi1_SpiegelTextArticle_4K{
    width: 90%;
    margin-left: 5%;
    font-size: 110%;
    font-family: 'Times New Roman', Times, serif;
}

.Schi1_SpiegelTextArticle_Medium{
    width: 90%;
    margin-left: 5%;
    font-size: 90%;
    font-family: 'Times New Roman', Times, serif;
}

.Schi1_SpiegelTextArticle_Mobile{
    width: 90%;
    margin-left: 5%;
    font-size: 65%;
    font-family: 'Times New Roman', Times, serif;
    
}

.Sch1_SpiegelCommentareArea{
    width: 30%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.Sch1_SpiegelCommentareArea_Mobile{
    width: 100%;
    height: 30%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    
}

.Sch1_SpiegelKommentar{
    border: 1px solid gray;
    margin-top: 2%;
    height: auto;
    font-size: 75%;
    padding-left: 2%;
}

.Sch1_SpiegelKommentar_Mobile{
    border: 1px solid gray;
    margin-top: 1%;
    height: auto;
    font-size: 40%;
    padding-left: 2%;
}

.Sch1_TaskArea{
    width: 30%;
}

.Sch1_TaskArea_Mobile{

    width: 100%;
    height: 20%;
}

.Sch1_TaskSchreibaufgabe{
    width: 90%;
    text-align: center;
    margin-top: 50%;
    margin-left: 5%;
    font-size: 120%;
}

.Sch1_TaskSchreibaufgabe_Medium{
    width: 90%;
    text-align: center;
    margin-top: 30%;
    margin-left: 5%;
    font-size: 110%;
}

.Sch1_TaskSchreibaufgabe_Mobile{
    width: 90%;
    text-align: center;
    margin-top: 1%;
    margin-left: 2%;
    font-size: 75%;
}

.Lernende{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 95%;
    display: flex;
    flex-direction: column;
    
}

.Lernende_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 20%;
    height: 75%;
    display: flex;
    flex-direction: column;
    
}

.LernendeTitel{
    width: 90%;
    margin-left: 5%;
    text-align: center;
    font-size: 180%;
    font-weight: 1000;
}

.LernendeTitel_Mobile{
    width: 90%;
    margin-left: 5%;
    text-align: center;
    font-size: 110%;
    font-weight: 1000;
}

.LernerFrame{
    width: 80%; 
    margin-left: 10%;
    margin-top: 2%;
    height: 35%;
    display: flex;
    flex-direction: row;
    background-color: rgb(205, 205, 205);
    border-radius: 15px;
}

.LernerFrame_Mobile{
    width: 90%;
    margin-left: 5%;
    margin-top: 10%;
    height: 30%;
    display: flex;
    flex-direction: row;
    background-color: rgb(205, 205, 205);
    border-radius: 15px;
    
}

.LernerFramePicture{
    margin-left: 2%;
    margin-top:1%;
    height: 90%;
    width: 20%;
}

.LernerFramePicture_Mobile{
    margin-left: 2%;
    margin-top:2%;
    height: 90%;
    width: 45%;
    justify-content: center;
    align-items: center;
}

.Bild_Finn{
    background-image: url('../../material/Finn.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%
}

.Bild_Mia{
    background-image: url('../../material/Mia.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%
}

.LernendenInfo{
    margin-left: 2%;
    margin-top:1%;
    height: 90%;
    width: 65%;
    display: flex;
    flex-direction: column;
}

.LernendenInfoP{
    margin-top: 1%;
    margin-top: 2%;
}

.LernendenInfo_4K{
    margin-left: 2%;
    margin-top:1%;
    height: 90%;
    width: 65%;
    display: flex;
    flex-direction: column;
}

.LernendenInfoP_4K{
    margin-top: 1%;
    margin-top: 2%;
    font-size: 150%;
}

.LernendenInfo_Mobile{
    margin-left: 2%;
    margin-top:2%;
    height: 90%;
    width: 45%;
    font-size: 70%;
}

.Schreibprodukt{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 95%;
    display: flex;
    flex-direction: column;
    
}

.Schreibprodukt_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 10%;
    height: 82%;
    display: flex;
    flex-direction: column;
    
}


.LernendenInfoP_Mobile{
    margin-top: 3%;
}

.M3CK1SNavigationArea{
    width: 30%;
    display: flex;
    flex-direction: row;
    height: 95%;
    justify-content: space-between;
}

.M3CK1SNavigationArea2{
    width: 15%;
    display: flex;
    flex-direction: row;
    height: 95%;
    justify-content: space-between;
}

.M3CK1SNavigationArea_Mobile{
    width: 90%;
    display: flex;
    flex-direction: row;
    height: 95%;
    margin-top: 3%;
    justify-content: space-between;    
}

.M3CK1SNavigationArea2_Mobile{
    width: 50%;
    display: flex;
    flex-direction: row;
    height: 95%;
    margin-top: 3%;
    justify-content: space-between;    
}

.M3CK1SNavElement{
   width: 4.5vw;
   height: 4.5vw;
}

.M3CK1SNavElement_Mobile{
    width: 15vw;
    height: 15vw;
 }

 .NavImage_Baurmann{
    background-image: url('../../material/Button_Baurmann.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Baurmann:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_Spiegel{
    background-image: url('../../material/Button_Spiegel.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Spiegel:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_Lernende{
    background-image: url('../../material/Button_Partner.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Lernende:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_Finn{
    background-image: url('../../material/Button_Finn.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Finn:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }


 .NavImage_Mia{
    background-image: url('../../material/Button_Mia.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Mia:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_finish{
    background-image: url('../../material/Button_fertig.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_finish:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_Baurmann_active{
    background-image: url('../../material/Button_Baurmann.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .NavImage_Spiegel_active{
    background-image: url('../../material/Button_Spiegel.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .NavImage_Lernende_active{
    background-image: url('../../material/Button_Partner.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .NavImage_Finn_active{
    background-image: url('../../material/Button_Finn.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .NavImage_Mia_active{
    background-image: url('../../material/Button_Mia.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .NavImage_finish_active{
    background-image: url('../../material/Button_fertig.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .SchreibproduktTitel{
    text-align: center;
    font-size: 180%;
    font-weight:500;
    margin-top: -2%;
    margin-bottom: 2%;
 }

 .SchreibproduktTitel_Mobile{
    text-align: center;
    font-size: 120%;
    font-weight:800;
    margin-top: -5%;
    margin-bottom: 2%;
 }

 
 
 .AnnotationArea{
    height: 90%;
  }

  .AnnotationArea_Mobile{
    height: 105%;
  }


.SpeicherSeite{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 95%;
    display: flex;
    flex-direction: column;
    
}

.SpeicherSeite_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 20%;
    height: 75%;
    display: flex;
    flex-direction: column;
    
} 

.SpeicherSeiteTitel{
    text-align: center;
    font-size: 180%;
    font-weight: 500;
}

.SpeicherSeiteTitel_Mobile{
    text-align: center;
    font-size: 120%;
    font-weight: 500;
}

.M3CK1S_SpeicherButtonArea{
    margin-top: 15%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    
}

.M3CK1SM3CK1S_SpeicherButtonArea_Mobile{
    
    margin-top: 55%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.M3CK1S_SpeicherButton{
    background-color: #e1743c;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 8%;
    padding-right: 8%;
    border-radius: 25px;
    font-size: 150%;
    font-weight: 500;
}

.M3CK1S_SpeicherButton:hover{
    background-color: #e1743c;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    cursor: pointer;
}

.M3CK1S_SpeicherButton_Mobile{
    background-color: #e1743c;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 9%;
    padding-right: 9%;
    border-radius: 25px;
    font-size: 110%;
}

.M3CK1S_SpeicherButton_Mobile:hover{
    background-color: #e1743c;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    cursor: pointer;
}

.M3CK1S_WeiterButtonArea{
    margin-top: 1%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;

}

.M3CK1S_WeiterButtonArea_Mobile{
    margin-top: 20%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.M3CK1S_SpeicherButton_gone{
    position: absolute;
    margin-left: -2000vw;
}

.M3CK1S_WeiterButton_gone{
    position: absolute;
    margin-left: -5000vw;
}

.M3CK1SLoader{
    width:8vw;
    height:8vw;
}

.M3CK1SLoader_Mobile{
    width: 15vw;
    height: 15vw;
}
.M3CK1SLoader_gone{
    position: absolute;
    margin-left: -2000vw;
}

*::-webkit-scrollbar {
    width: 12px;
  }
  
  *::-webkit-scrollbar-track {
    background: rgba(255, 166, 0, 0);
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #e1743c;
    
  }