.M3QWrapper{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-x: hidden;
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
    display: flex;
    flex-direction: column;
    background: linear-gradient(-45deg, #5d5d5d, rgb(197, 197, 197)5%, rgb(196, 196, 196) 10%, rgb(215, 215, 215) 20%, rgb(241, 241, 241) 50%, rgb(215, 215, 215) 80%, rgb(215, 215, 215) 90%, rgb(186, 186, 186)95%, #434343);
}

.M3Questionnaire{
    width: 90%;
    margin-left: 5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.M3Q_Titel{
    width: 100%;
    text-align: center;
    margin-top: 3%;
    font-size: 190%;
    font-weight: 600;
}

.M3Q_Titel_Mobile{
    width: 100%;
    text-align: center;
    margin-top: 3%;
    font-size: 160%;
    font-weight: 600;
}

.M3QBox{
    width: 90%;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
}

.M3QBox_Mobile{
    width: 90%;
    margin-top: 7%;
    display: flex;
    flex-direction: column;
}

.M3QBoxLabel{
    font-size: 130%;
    height: 45%;
    font-weight: 600;
}

.M3QBoxLabel_Mobile{
    font-size: 110%;
    height: 45%;
    font-weight: 600;
}


.M3QQsingle{
    width: 100%;
    margin-top: 1%;
    height: 45%;
    display: flex;
    flex-direction: row;
    font-size: 130%;
}

.M3QQLabel{
    margin-left: 2%;
    font-size: 120%;
}

.M3QQLabel_Mobile{
    margin-left: 2%;
    font-size: 120%;
    margin-top: 5%;
}

.M3Qinput1{
    margin-left: 2%;
    font-size: 130%;
    text-align: center;
}

.M3Qinput1_Mobile{
    margin-left: 2%;
    font-size: 110%;
    text-align: center;
}

.M3QScaleBox{
    width: 90%;
    margin-top: 5%;
    display: flex;
    height: auto;
    flex-direction: column;
}

.M3QScaleBox_Mobile{
    width: 90%;
    margin-top: 5%;
    display: flex;
    height: auto;
    flex-direction: column;
}

.M3QScaleBoxSuperTitel{
    height: 5vh;
    font-size: 150%;
    font-weight: 1000;
}

.M3QScaleBoxSuperTitel_Mobile{
    height: 5vh;
    font-size: 130%;
    font-weight: 1000;
    margin-bottom: 18%;
}

.M3QScaleBoxScaleArea{
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
}

.M3QScaleBoxScaleLabel{
    font-size: 140%;
    font-weight: 600;
}

.M3QScaleBoxScaleLabel_Mobile{
    font-size: 110%;
    font-weight: 600;
}

.MQ3Scale{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1%;
    margin-bottom: 5%;
}

.MQ3Scale_Mobile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6%;
    margin-bottom: 5%;
}

.M3QScaleStep{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.M3QScaleCheckBox{
    width: 100%;
    height: 100%;
}

.M3QScaleCheckBoxLabel{
    font-size: 120%;
}

.M3QScaleCheckBoxLabel_Mobile{
    font-size: 75%;
}

.M3QButtonArea{
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

}

.M3QButtonArea_Mobile{
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

}

.M3QButton{
    background-color: #e1743c;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 25px;
    box-shadow: 1px 1px 5px 2px rgb(124, 124, 124);
    font-size: 110%;
    font-weight: 700;
}

.M3QButton:hover{
    cursor: pointer;
    background-color: #ff5500;
    transition: 1s;
    box-shadow: 1px 1px 5px 2px rgb(40, 40, 40);
}

.M3QButton_gone{
    position: absolute;
    margin-left: -2000vw;
}