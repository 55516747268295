.ButtonWrapper{
    width: 45%;
    height: auto;
    display: flex;
    flex-direction: row;
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    border-radius: 25px;
    
}

.ButtonWrapper_Mobile{
    width: 65%;
    height: auto;
    display: flex;
    flex-direction: row;
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    border-radius: 25px;
    margin-top: -25%;
    margin-left: -5%;
}

.ButtonWrapper_Ipad{
    width: 45%;
    height: auto;
    display: flex;
    flex-direction: row;
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    border-radius: 25px;
    margin-top: 4%;
}

.ButtonWrapper_Ipad_upright{
    width: 45%;
    height: auto;
    display: flex;
    flex-direction: row;
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    border-radius: 25px;
    margin-top: 4%;
}


.ButtonIcon{
    margin-left: 5%;
    width: 0%;
    
    
}

.ButtonText_Ipad{
    width: 90%;
    text-align: center;
    margin-left: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 130%;
    padding-top: 2%;
    padding-bottom: 2%;
    color: rgb(225, 225, 225);
    font-weight: 1000;
}


.ButtonText_Ipad_upright{
    width: 90%;
    text-align: center;
    margin-left: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 130%;
    padding-top: 2%;
    padding-bottom: 2%;
    color: rgb(225, 225, 225);
    font-weight: 1000;
}

.ButtonText{
    width: 90%;
    text-align: center;
    margin-left: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 150%;
    padding-top: 2%;
    padding-bottom: 2%;
    color: rgb(225, 225, 225);
}

.ButtonText_Mobile{
    width: 90%;
    text-align: center;
    margin-left: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
    font-weight: 1000;
    color: rgb(225, 225, 225);
    
}