.M3Ergebnisse{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 95%;
    display: flex;
    flex-direction: column;
    
}

.M3Ergebnisse_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 10%;
    height: 82%;
    display: flex;
    flex-direction: column;
    
}

.M3ErgebnisseTitel{
    text-align: center;
    font-size: 200%;
    font-weight: 800;
    margin-top: -2%;
}

.M3ErgebnisseTitel_Mobile{
    text-align: center;
    font-size: 150%;
    font-weight: 800;
}

.M3ErgebnisseArea{
    width: 100%;
    height: 85%;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
}

.M3ErgebnisseArea_Mobile{
    width: 100%;
    height: 91%;
    margin-top: 6%;
    display: flex;
    flex-direction: column;
}

.PrintWindow{
    position: absolute;
    width: 90vw;
    height: auto;
    background-color: rgba(229, 232, 235, 0.795);
    Top: 0;
    Left: 5%;
    display: flex;
    flex-direction: column;
}

.PrintWindow_gone{
    position: absolute;
    margin-left: -5000vw;
}

.Printerbutton{
    position: absolute;
    Right: 2%;
    width: 5vw;
    height: 5vw;
    border-radius: 100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
}

.Printerbutton:hover{
    cursor: pointer;
    box-shadow:  1px 2px 8px 2px #e1743c;
}

.Printerbutton_Mobile{
    position: absolute;
    Right: 2%;
    bottom: 10%;
    width:15vw;
    height: 15vw;
    border-radius: 100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    
}

.Printerbutton_Mobile:hover{
    cursor: pointer;
    box-shadow:  1px 2px 8px 2px #e1743c;
}

.Printerbutton_Button{
    border-radius: 100%;
    width: 100%;
    height: 100%;
    background-image: url('../../material/savedata.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
}


