.Feedbacktrichter_Wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.FeedbackTrichter_Top{
    height: 15%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.FeedbackTrichter_Top_Mobile{
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.FeedbackTrichter_Middle{
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.FeedbackTrichter_Middle_Mobile{
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: row;
}


.FeedbackTrichter_Diagramm{
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.FTD_R1{
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.FTD_R2{
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.FTD_R1C1{
    width: 12.5%;
    height:100%;
    border-radius: 0px 0px 0px 0px;
}

.FTD_R1C2{
    border-left: 4px solid rgb(86, 86, 86);
    border-bottom: 4px solid rgb(86, 86, 86);
    border-radius: 0px 0px 0px 15px;
    width: 12.5%;
    height:100%;
}

.FTD_R1C3{
    border-right: 4px solid rgb(86, 86, 86);
    border-bottom: 4px solid rgb(86, 86, 86);
    width: 12.5%;
    height:100%;
}

.FTD_R1C4{
    border-bottom: 4px solid rgb(86, 86, 86);
    width: 12.5%;
    height:100%;
}
.FTD_R1C5{
    border-right: 4px solid rgb(86, 86, 86);
    border-bottom: 4px solid rgb(86, 86, 86);
    width: 12.5%;
    height:100%;
}
.FTD_R1C6{
    
    border-bottom: 4px solid rgb(86, 86, 86);
    width: 12.5%;
    height:100%;
}

.FTD_R1C7{
    border-right: 4px solid rgb(86, 86, 86);
    border-bottom: 4px solid rgb(86, 86, 86);
    border-radius: 0px 0px 15px 0px;
    width: 12.5%;
    height:100%;
}

.FTD_R1C8{
    width: 12.5%;
    height:100%;
}

.FTD_R2C1{
    border-right: 4px solid rgb(86, 86, 86);
    width: 50%;
    height:100%;
}

.FTD_R2C2{
    border-left: 4px solid rgb(86, 86, 86);
    width: 50%;
    height:100%;
}

.FeedbackTrichter_Bottom{
    height: 40%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.UserKommentarL1{
    width: 25%;
    height: 100%;
    background-color: #e1743c;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserKommentarL1_Large{
    width: 80%;
    height: 100%;
    background-color: #e1743c;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserKommentarL1_Small{
    width: 5%;
    height: 100%;
    background-color: #e1743c;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

.UserKommentarL2{
    width: 25%;
    height: 100%;
    background-color: #3cbae1;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserKommentarL2_Large{
    width: 80%;
    height: 100%;
    background-color: #3cbae1;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserKommentarL2_Small{
    width: 5%;
    height: 100%;
    background-color: #3cbae1;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}

.UserKommentarL3{
    width: 25%;
    height: 100%;
    background-color: #ba3ce1;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserKommentarL3_Large{
    width: 80%;
    height: 100%;
    background-color: #ba3ce1;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserKommentarL3_Small{
    width: 5%;
    height: 100%;
    background-color: #ba3ce1;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}



.UserKommentarL4{
    width: 25%;
    height: 100%;
    background-color: #4fb438;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.UserKommentarL4_Large{
    width: 80%;
    height: 100%;
    background-color: #4fb438;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.UserKommentarL4_Small{
    width: 5%;
    height: 100%;
    background-color: #4fb438;
    border-radius: 25px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;

}

.UserKommentarText{
    background-color: rgb(238, 238, 238);
    width: 90%;
    border-radius: 5px;
    height: 90%;
    overflow-y:scroll;
    font-size: 90%;
    padding-left: 2%;
}

.UserKommentarText_Mobile{
    background-color: rgb(238, 238, 238);
    width: 88%;
    border-radius: 5px;
    height: 85%;
    font-size: 80%;
    padding-left: 2%;
    overflow-y:scroll;

}

.UserFeedbackArea{
    background-color: rgb(211, 211, 211);
    height: 100%;
    width: 85%;
    border-radius: 25px;
    border: 4px solid rgb(86, 86, 86);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.UserFeedbackField{
    width: 98%;
    height: 90%;
    display: flex;
    flex-direction: column;
    border: 2px solid black;
    background-color: white;
    overflow-y: scroll;
}

.FeedbackTaskArea{
    width: 85%;
    height: 100%;
    display: flex;

}

.FeedbackTaskTask{
    width: 100%;
    font-size: 90%;
    font-weight: 500;
    margin-top: -0.5%;
}

.UserFeedbackSection{
    width: 100%;
    height: auto;
    border: 1px solid rgb(220, 220, 220);

}

.UserFeedbackSection_Mobile{
    width: 100%;
    height: auto;
    font-size: 85%;
    border: 1px solid rgb(220, 220, 220);
 
}

.FeedbackTaskTask_Mobile{
    width: 100%;
    font-size: 65%;
    font-weight: 500;
    margin-top: -5%;
}

.LTextArea{
    width: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}



.LTextButton{
    background-color: #e1743c;
    width: 100%;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 15px;
    font-weight: 800;
    font-size: 100%;
}

.LTextButton_Mobile{
    background-color: #e1743c;
    width: 100%;
    text-align: center;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 15px;
    font-weight: 800;
    font-size: 90%;
}

.displayLernerText{
    position: absolute;
    width: 98%;
    height: 85%;
    margin-left: -2%;
    background-color: white;
    z-index: 99;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.displayLernerTextTop{
    width: 92%;
    height: 10%;
    margin-left: 4%;
    display: flex;
    flex-direction: row;
    margin-top: 2%;
}

.displayLernerTextTopTitelArea{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.displayLernerTextTopTitelArea_Mobile{
    width: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.displayLernerTextTopTitel{
   font-size: 150%;
   font-weight: 500;;
}

.displayLernerTextTopTitel_Mobile{
    font-size: 110%;
    font-weight: 500;;
 }

.displayLernerTextBody{
    width: 90%;
    margin-left: 5%;
    height: 70%;
    overflow-y: scroll;
}

.displayLernerTextTextRow{
    margin-top: 0.2%;
}

.displayLernerTextTextRow_Mobile{
    margin-top: 0.2%;
    font-size: 90%;
}

.displayLernerTextCloseArea{
    width: 4vw;
    height: 4vw;
}

.displayLernerTextCloseArea_Mobile{
    width: 10vw;
    height: 10vw;
}

.displayLernerTextCloseButton{
    background-image: url('../material/Close2.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
}

.displayLernerText_gone{
    position: absolute;
    margin-left: -2000vw;
}