.AnmeldeWrapper{
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
}

.AnmeldeTitel{
    margin-top: 10%;
    margin-left: 5%;
    color: #e1743c;
    font-size: 220%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.AnmeldeTitel_Mobile{
    margin-top: 30%;
    margin-left: 5%;
    color: #e1743c;
    font-size: 150%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.AnmeldeRow{

    margin-top: 2%;
    margin-left: 5%;
    width: 90%;
    display: flex;
    flex-direction: row;
    height: auto;
}

.AnmeldeRow_Mobile{
    margin-top: 10%;
    margin-left: 5%;
    width: 90%;
    display: flex;
    flex-direction: row;
    height: auto;
}

.AnmeldeLabel{
    color: #e0e0e0;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 150%;
    width: 40%;
}

.AnmeldeLabel_Mobile{
    color: #e0e0e0;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 100%;
    width: 50%;
}

.FieldArea{
    display: flex;
    flex-direction: row;
}

.FieldArea_Mobile{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.AnmeldeField{
    width: 45%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 150%;
    text-align: center;
    margin-right: 1%;
}

.AnmeldeField_Mobile{
    width: 80%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 110%;
    text-align: center;
    margin-right: 1%;
    margin-bottom: 5%;
}

.AnmeldeField2{
    width: 44%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 150%;
    text-align: center;
    margin-right: 1%;
}

.AnmeldeField2_Mobile{
    width: 40%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 100%;
    text-align: center;
    margin-right: 1%;
}

.AnmeldeButton{
    width: 100%;
    height: 100%;
}

.AnmeldeButton_gone{
   position: absolute;
   margin-left: -500vw;
}

.SuccessWindow{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #e1743c;
    display: flex;
    flex-direction: column;
}

.SuccessWindow_gone{
    position: absolute;
    margin-left: -500vw;
}

.SWTitel{
    width: 100vw;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 20%;
    font-weight: 1000;
    font-size: 300%;
}

.SWTitel_Mobile{
    width: 100vw;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 70%;
    font-weight: 1000;
    font-size: 200%;
}

.SWCloseLink{
    margin-left: 40%;
    width: 20%;
    margin-top: 5%;
    border: 2px solid black;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 150%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.SWCloseLink_Mobile{
    margin-left: 35%;
    width: 30%;
    margin-top: 15%;
    border: 2px solid black;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 160%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.SWCloseLink:hover{
    background-color: rgb(236, 236, 236);
    transition: 1s;
    cursor: pointer;
}