.M3HW_C_Wrapper{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overflow-x: hidden;
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
    display: flex;
    flex-direction: column;
    background: linear-gradient(-45deg, #5d5d5d, rgb(197, 197, 197)5%, rgb(196, 196, 196) 10%, rgb(215, 215, 215) 20%, rgb(241, 241, 241) 50%, rgb(215, 215, 215) 80%, rgb(215, 215, 215) 90%, rgb(186, 186, 186)95%, #434343);
}

.M3HW_C_Page1{
    overflow-y: scroll;
    height: auto;
}

.M3HW_C_Page1_gone{
    position: absolute;
    margin-left: -2000vw;
}

.M3HW_C_Page2_gone{
    position: absolute;
    margin-left: -2000vw;
}

.M3HW_C_Page3_gone{
    position: absolute;
    margin-left: -2000vw;
}

.M3HW_C_Page2{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}

.M3HW_C_Page3{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}

.M3HW_C_Page2_Mobile{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    overflow-y: scroll;
}

.M3HW_C_Page3_Mobile{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    overflow-y: scroll;
}



.M3HW_A_P1_Top{
    height: 10vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.M3HW_A_P1_Top_Mobile{
    height: 6vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}


.M3HW_A_P1_Mid{
    height: 40vh;
    margin-left: 10%;
    width: 80%;
    font-size: 130%;
    font-weight: 500;
}

.M3HW_A_P1_Mid_Mobile{
    height: auto;
    margin-left: 5%;
    width: 90%;
    font-size: 90%;
    font-weight: 500;
}


.M3HW_A_P1_LoaderArea{
    height: 20vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.M3HW_A_P1_LoaderArea_Mobile{
    height: 25vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.HW_A_LoaderBox{
    height: 8vw;
    width: 8vw;
}

.HW_A_LoaderBox_gone{
    position: absolute;
    margin-left: -2000vw;
}

.HW_A_LoaderBox_Mobile{
    height: 21vw;
    width: 21vw;
}

.M3HW_A_P1_Bottom{
    height: 20vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.M3HW_A_P1_Bottom_Mobile{
    height: 2vh;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 5vh;
}

.M3HW_C_Button{
    background-color: #e1743c;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 25px;
    font-size: 130%;
    font-weight: 600;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 999;
}

.M3HW_C_Button_Mobile{
    background-color: #e1743c;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 25px;
    font-size: 130%;
    font-weight: 600;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 999;
}

.M3HW_C_Button_gone{
    position: absolute;
    margin-left: -2000vw;
}

.M3HW_C_Button:hover{
    background-color: #fe5500;
    cursor: pointer;
    transition: 1s;
}



.M3HW_A_P1_Titel{
    font-size: 210%;
    font-weight: 800;
}

.M3HW_A_P1_Titel_Mobile{
    font-size: 160%;
    font-weight: 800;
}

.M3HW_A_FeedbackArea{
    width: 45%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.M3HW_A_FeedbackArea2{
    width: 41%;
    height: 90%;
    display: flex;
    flex-direction: column;
    margin-left: 5%;
}

.M3HW_A_FeedbackArea_Mobile{
    width: 90%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    overflow-y: scroll;
}

.M3HW_A_FeedbackArea2_Mobile{
    width: 90%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    overflow-y: scroll;
    margin-left: 5%;
}

.M3HW_A_CommentArea{
    width: 45%;
    height: 90%;
    display: flex;
    flex-direction: column;
}

.M3HW_A_CommentArea_Mobile{
    width: 90%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    margin-top: 7%;
}

.M3HW_A_FeedbackArea_Titel{
    width: 100%;
    text-align: center;
    font-size: 160%;
    font-weight: 600;

}

.M3HW_A_FeedbackArea_Text{
    margin-top: 3%;
    height: 90%;
    overflow-y: scroll;
    font-size: 120%;
    font-weight: 500;
    text-align: justify;
    hyphens: auto;
}

.M3HW_A_FeedbackArea_Text_Mobile{
    margin-top: 3%;
    height: 90%;
    overflow-y: scroll;
    font-size: 100%;
    font-weight: 500;
    text-align: justify;
    hyphens: auto;
}

.M3HW_C_TextArea{
    margin-top: 3%;
    height: 75%;
    font-size: 130%;
    font-weight: 500;
    background-color: transparent;
}

.M3HW_C_TextArea_Mobile{
    margin-top: 3%;
    min-height: 80%;
    font-size: 100%;
    font-weight: 500;
    background-color: transparent;
}

.M3HW_A_CommentArea_ButtonArea{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.M3HW_A_CommentArea_ButtonArea_Mobile{
    width: 100%;
    height: 15ch;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 5vh;
}

.M3HW_A_CommentArea_Button{
    background-color: #e1743c;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 25px;
    font-size: 130%;
    font-weight: 600;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
}

.M3HW_A_CommentArea_Button:hover{
    background-color: #fe5500;
    cursor: pointer;
    transition: 1s;
}

.M3HW_C_Page3_wait{
    width: 100%;
    height: 100%;
}

.M3HW_C_Page3_wait_gone{
    position: absolute;
    margin-left: -2000vw;
}

.M3HW_C_Page3_waitTextArea{
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.M3HW_C_Page3_waitText{
    font-size: 200%;
    font-weight: 600;
}

.M3HW_C_Page3_waitText_Mobile{
    font-size: 150%;
    font-weight: 600;
    text-align: center;
    padding-top: 20%;
}

.M3HW_C_Page3_aktualBut{
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 25px;
    font-size: 150%;
    font-weight: 600;
    background-color: #e1743c;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
}

.M3HW_C_Page3_aktualBut:hover{
    cursor: pointer;
    background-color: #ff5500;
    box-shadow:  1px 1px 5px 1px rgb(30, 30, 30);
    transition: 1s;
}

.M3HW_C_Page3_display{
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    width: 100%;

}

.M3HW_C_Page3_display_Mobile{
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    width: 100%;

}

.M3HW_C_Druckansicht{
    position: absolute;
    background-color: white;
}

.M3HW_C_Druckansicht_gone{
    position: absolute;
    margin-left: -2000vw;
}

.Printerbutton_B{
    position: absolute;
    Right: 2%;
    width: 5vw;
    height: 5vw;
    border-radius: 100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 999;
}

.Printerbutton_B:hover{
   cursor: pointer;
}