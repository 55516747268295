.LandingpageWrapper{
    width: 100vw;
    height: 100vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
}

.LandingpageBody{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(180deg, #000000 55%, rgb(135, 135, 135));
}

.LandingpageTop{
    color: white;
    height: 7vh;
}

.LandingpageTop_Ipad{
    color: white;
    height: 10vh;
}

.LandingpageTop_Mobile{
    color: white;
    height: 9vh;
}

.LandingpageMiddle{
    color: white;
    height: 65vh;
    display: flex;
    flex-direction: row;
}

.LandingpageMiddle_Ipad{
    color: white;
    height: 55vh;
    display: flex;
    flex-direction: row;
}

.LandingpageMiddle_Mobile{
    color: white;
    height: 55vh;
    display: flex;
    flex-direction: row;
}

.LandingpageBottom{

    color: white;
    height: 20vh;
}

.LandingpageBottom_Ipad{

    color: white;
    height: 20vh;
}

.LandingpageBottom_Mobile{

    color: white;
    height: 20vh;
}

.LandingpageMiddePictureArea{
    width: 40%;
}

.LandingpageMiddleTitelArea{
    width: 60%;
}

.LandingPageAIPic{
    background-image: url('../material/Android.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 130%;
    transform: rotate(14deg);
    margin-top: -0%;
    margin-left: 10%;
    z-index: 0;
}

.LandingPageAIPic_Ipad{
    background-image: url('../material/Android.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 160%;
    transform: rotate(14deg);
    margin-top: -2%;
}

.LandingPageAIPic_Ipad_upright{
    background-image: url('../material/Android.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 200%;
    height: 160%;
    transform: rotate(14deg);
    margin-top: -5%;
    margin-left: -110%;
}

.LandingPageAIPic_Mobile{
    background-image: url('../material/Android.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 230%;
    height: 160%;
    transform: rotate(14deg);
    margin-top: 8%;
    margin-left: -140%;
}

.LandingPageBottomBox{
    background-color: #102131;
    width: 80%;
    height: 20vh;
    z-index: 55;
    position: absolute;
    box-shadow: 1px 1px 5px 2px rgb(11, 11, 11);
    display: flex;
    flex-direction: column;
    justify-items: center;
}

.LandingPageBottomBox_Ipad{
    background-color: #102131;
    width: 90%;
    height: 20vh;
    z-index: 55;
    position: absolute;
    box-shadow: 1px 1px 5px 2px rgb(11, 11, 11);
    display: flex;
    flex-direction: column;
    justify-items: center;
}

.LandingPageBottomBox_Mobile{
    background-color: #102131;
    width: 95%;
    height: 20vh;
    z-index: 55;
    position: absolute;
    box-shadow: 1px 1px 5px 2px rgb(11, 11, 11);
    display: flex;
    flex-direction: column;
    justify-items: center;
}

.LandingPageTitelField{
    margin-top: 15%;
    height: 45%;
}

.LandingPageTitelField_Ipad{
    margin-top: 20%;
    height: 40%;
}

.LandingPageTitelField_Ipad_upright{
    margin-top: 50%;
    height: 30%;
}

.LandingPageTitelField_Mobile{

    margin-top: 75%;
    height: 40%;
}

.LandingPageTitelRow1{

    height: 55%;
    display: flex;
    flex-direction: row;
}
.LandingPageTitelRow2{

    height: 55%;
    margin-top: -3.5%;
}

.LandingPageTitelRow1_Ipad{

    height: 55%;
    display: flex;
    flex-direction: row;
}

.LandingPageTitelRow1_Ipad_upright{

    height: 35%;
    display: flex;
    flex-direction: row;
}

.LandingPageTitelRow2_Ipad{

    height: 55%;
    margin-top: -2.5%;
}

.LandingPageTitelRow2_Ipad_upright{
    height: 25%;
    margin-top: 0%;
}

.LandingPageTitelRow1_Mobile{

    height: 55%;
    display: flex;
    flex-direction: row;
    padding-top: 0%;
}
.LandingPageTitelRow2_Mobile{

    height: 55%;
    margin-top: -25%;
}

.TitelElement{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e0e0e0 65%, #595959);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 520%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TitelElement_Colored{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 520%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TitelElement_Ipad{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e0e0e0 65%, #595959);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 390%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TitelElement_Ipad_upright{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e0e0e0 65%, #595959);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 290%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TitelElement_Colored_Ipad{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 390%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TitelElement_Colored_Ipad_upright{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 290%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TitelElement_Mobile{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e0e0e0 65%, #595959);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 150%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.TitelElement_Colored_Mobile{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 150%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.LandingBoxTitel_4K{

    text-align: right;
    margin-right: 10%;
    margin-top: 1%;
    font-size: 250%;
    font-weight: 800;
}

.LandingBoxTitel{

    text-align: right;
    margin-right: 10%;
    margin-top: 0.0%;
    font-size: 220%;
    font-weight: 800;
}

.LandingBoxTitel_Ipad{

    text-align: right;
    margin-right: 15%;
    margin-top: 1%;
    font-size: 250%;
    font-weight: 800;
}

.LandingBoxTitel_Ipad_upright{
    margin-top: 5%;
    text-align: right;
    margin-right: 5%;
    font-size: 180%;
    font-weight: 800;
}

.LandingBoxTitel_Mobile{

    text-align: right;
    margin-right: 10%;
    margin-top: 8%;
    font-size: 160%;
    font-weight: 800;
    margin-bottom: 2%;
}

.LandindBoxSubtitel_4K{

    text-align: right;
    margin-right: 2%;
    font-size: 200%;
    margin-top: 0.5%;
}

.LandindBoxSubtitel{

    text-align: right;
    margin-right: 2%;
    font-size: 150%;
}

.LandindBoxSubtitel_Ipad{
    text-align: right;
    margin-right: 2%;
    font-size: 180%;
}

.LandindBoxSubtitel_Ipad_upright{
    text-align: right;
    margin-right: 2%;
    font-size: 150%;
    margin-top: 2%;
}

.LandindBoxSubtitel_Mobile{
    text-align: right;
    margin-right: 2%;
    margin-top: 1%;
    font-size: 75%;
}