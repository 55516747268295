.UserErgebnisWrapper{
    width: 90%;
    height: 95%;
    background-color: rgb(239, 240, 230);
    box-shadow: 1px 1px 5px 1px rgb(87, 87, 87);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.UserErgebnisWrapper_Mobile{
    width: 99%;
    height: 99%;
    background-color: rgb(239, 240, 230);
    box-shadow: 1px 1px 5px 1px rgb(87, 87, 87);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.UserErgebnisTitel{
    text-align: center;
    margin-top: 2%;
    font-size: 150%;
    font-family: Arial, Helvetica, sans-serif, 'Arial Narrow Bold', sans-serif;
    font-weight: 600;
}

.UserErgebnisTitel_Mobile{
    text-align: center;
    margin-top: 2%;
    font-size: 120%;
    font-family: Arial, Helvetica, sans-serif, 'Arial Narrow Bold', sans-serif;
}

.UserErgebnisKommentareArea{
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 85%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.UserErgebnisKommentareArea_Mobile{
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 85%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.UserErgebnisKommentareAreaTitel{
    width: 100%;
    font-size: 100%;
    font-weight: 500;
}

.UserErgebnisKommentareAreaTitel_Mobile{
    width: 100%;
    font-size: 90%;
    font-weight: 500;
}

.UserErgebnisKommentareBlock{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    margin-bottom: 1%;
}

.UserErgebnisKommentareBlock_Mobile{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    margin-bottom: 1%;
}

.UserErgebnisKommentareBlockTitel{
    font-size: 100%;
    font-family: Arial, Helvetica, sans-serif, 'Arial Narrow Bold', sans-serif;
    font-weight: 700;
}

.UserErgebnisKommentareBlockTitel_Mobile{
    font-size: 85%;
    font-family: Arial, Helvetica, sans-serif, 'Arial Narrow Bold', sans-serif;
    font-weight: 700;
}

.UserErgebnisKommentareBlockText{
    font-size: 100%;
    font-family: Arial, Helvetica, sans-serif, 'Arial Narrow Bold', sans-serif;
    font-weight: 200;
}

.UserErgebnisKommentareBlockText_Mobile{
    font-size: 85%;
    font-family: Arial, Helvetica, sans-serif, 'Arial Narrow Bold', sans-serif;
    font-weight: 200;
}

.UserErgebnisFeedbackArea{
    border-bottom: 1px solid black;
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
}

.UserErgebnisFeedbackArea_Mobile{
    border-bottom: 1px solid black;
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}

.UserErgebnisFeedbackText{
    font-size: 100%;
    font-family: Arial, Helvetica, sans-serif, 'Arial Narrow Bold', sans-serif;
    font-weight: 200;
}

.UserErgebnisFeedbackText_Mobile{
    font-size: 85%;
    font-family: Arial, Helvetica, sans-serif, 'Arial Narrow Bold', sans-serif;
    font-weight: 200;
}