li::marker {
    color: #e1743c;
  }

.Philipp2023Wrappper{
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
}

.P2023_Grid_Row_neutral1{
    height: 40%;
    display: flex;
    flex-direction: row;
    transition: 1s;
}

.P2023_Grid_Row_neutral2{
    height: 20%;
    display: flex;
    flex-direction: row;
    transition: 1s;
}

.P2023_Grid_Cell_neutral{
    width: 33.3%;
    transition: 1s;
}

.P2023_Grid_Row_max{
    height:80%;
    display: flex;
    flex-direction: row;
    transition: 1s;
}

.P2023_Grid_Row_min{
    height:10%;
    display: flex;
    flex-direction: row;
    transition: 1s;
}

.P2023_Grid_Cell_max{
    width: 80%;
    transition: 1s;
}

.P2023_Grid_Cell_min{
    width: 10%;
    transition: 1s;
}


.connectorHorizontalCell{
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
}

.connectorHorizontal{
    width: 100%;
    height: 1%;
    border-top: 6px dotted #e1743c;
}

.connectorHorizontal_Mobile{
    width: 100%;
    height: 1%;
    border-top: 3px dotted #e1743c;
}

.connectorVerticalCell{
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
}

.connectorVertical{
    width: 1%;
    height: 100%;
    border-left: 6px dotted #e1743c;
}

.connectorVertical_Mobile{
    width: 1%;
    height: 100%;
    border-left: 3px dotted #e1743c;
}

.PhilippContentBox{
    border: 2px solid rgb(65, 65, 65);
    background-color: rgb(211, 211, 211);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.PhilippContentBox_Label_gone{
    position: absolute;
    margin-left: -2000vw;
}

.PhilippContentBox_Label{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.PhilippLabelTitel{
    font-size: 180%;
    font-weight: 500;
    text-align: center;
}

.PhilippLabelTitel_Mobile{
    font-size: 110%;
    font-weight: 500;
    text-align: center;
}

.PhilippContentBox1_Content{
    width: 100%;
    height: 100%;
}

.PhilippContentBox_Content_gone{
    position: absolute;
    margin-left: -2000vw;
}

.PhilippBoxContentTitel{
    margin-left: 2%;
    margin-top: 1%;
    font-size: 150%;
    font-weight: 800;
    text-align: center;
}

.PhilippBoxContentContent{
    margin-left: 2%;
    margin-top: 1%;
    font-size: 100%;
}


.PhilippBoxContentTitel_Mobile{
    margin-left: 2%;
    margin-top: 1%;
    font-size: 110%;
    font-weight: 800;
    text-align: center;
}

.PhilippBoxContentContent_Mobile_start{
    margin-left: 2%;
    margin-top: 1%;
    font-size: 80%;
}

.PhilippBoxContentContent_Mobile{
    margin-left: 2%;
    margin-top: 1%;
    font-size: 50%;
}
