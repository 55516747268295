.SchulertextViewer_Wrapper{
    width: 90%;
    height: 95%;
    background-color: rgb(239, 240, 230);
    box-shadow: 1px 1px 5px 1px rgb(87, 87, 87);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.SchulertextViewer_Wrapper_Mobile{
    margin-top: 5%;
    margin-bottom: 5%;
    width: 99%;
    height: 99%;
    background-color: rgb(239, 240, 230);
    box-shadow: 1px 1px 5px 1px rgb(87, 87, 87);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.Schulertext_Titel{
    width: 90%;
    margin-top: 5%;
    font-size: 150%;
    font-weight: 600;
    font-family:Arial, Helvetica, sans-serif;
}

.Schulertext_Titel_Mobile{
    width: 90%;
    margin-top: 5%;
    font-size: 110%;
    font-weight: 600;
    font-family:Arial, Helvetica, sans-serif;
}

.Schulertext_Area{
    width: 90%;
    height: 85%;
    margin-top: 2%;
}

.Schulertext_Area_Mobile{
    width: 90%;
    height: auto;
    margin-top: 2%;
}

.Schulertext_Row{
    margin-top: 0.8%;
    font-size: 90%;
}

.Schulertext_Row_Mobile{
    margin-top: 1%;
    font-size: 50%;
}