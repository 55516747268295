.BaurmannPohl_Wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.BaurmannPohl_Wrapper_Mobile{
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
}

.BaurmannPohlModelArea{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BaurmannPohlModelArea_Mobile{
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
}

.BaurmannPohlExplainArea{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.BaurmannPohlExplainArea_Mobile{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.BaurmannPohlRow1{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 16%;
}

.BaurmannPohlRow2{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 8%;
}

.BaurmannPohlRow3{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 16%;
}

.BaurmannPohlRow4{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 16%;
}

.BaurmannPohlRow5{
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 16%;
}

.BaurmannPohlRow6{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 8%;
}

.BaurmannPohlRow7{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 16%;
}

.BaurmannPohlCell1{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
}

.BaurmannPohlCell2{
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
}

.BaurmannPohlCell3{
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: column;
}

.BaurmannPohlFieldSchreibkompetenz{
    background-color: rgb(185, 185, 185);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 15px;
    border: 5px solid rgb(185, 185, 185);
}

.BaurmannPohlLabelSchreibkompetenz{
    font-size: 150%;
    font-weight: 800;
    text-align: center;
}

.BaurmannPohlLabelSchreibkompetenz_4K{
    font-size: 300%;
    font-weight: 800;
    text-align: center;
}

.BaurmannPohlLabelSchreibkompetenz_Mobile{
    font-size: 80%;
    font-weight: 800;
    text-align: center;
}

.BaurmannPohl_GeradeSenkrecht{
    border: 3px solid rgb(185, 185, 185);
    height: 100%;
}

.BaurmannPohl_GeradeWaagerecht{
    border: 3px solid rgb(185, 185, 185);
    width: 100%;

}

.BaurmannPohl_GeradeSenkrecht_Mobile{
    border: 1.5px solid rgb(185, 185, 185);
    height: 100%;
}

.BaurmannPohl_GeradeWaagerecht_Mobile{
    border: 1.5px solid rgb(185, 185, 185);
    width: 100%;

}

.CellSplit1{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
}

.CellSplit2{
    width: 50%;
    height: 100%;
}

.BaurmannPohl_Kurve1{
    border-top: 3px solid rgb(185, 185, 185);
    border-left: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
    
}

.BaurmannPohl_Kurve1_1{
    border-top: 6px solid rgb(185, 185, 185);
    border-left: 6px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
    
}

.BaurmannPohl_KurveLGegen{
    border-right: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100% 
}

.BaurmannPohl_KurveLTopGegen{
    border-bottom: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_KurveLTopGegen2{
    border-bottom: 6px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_Kurve2{
    border-bottom: 3px solid rgb(185, 185, 185);
    border-left: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_Kurve2_2{
    border-bottom: 6px solid rgb(185, 185, 185);
    border-left: 6px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_KurveLBottomGegen{
    border-top: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_KurveLBottomGegen2{
    border-top: 6px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_Kurve3{
    border-bottom: 3px solid rgb(185, 185, 185);
    border-right: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
    
}

.BaurmannPohl_Kurve4{
    border-top: 3px solid rgb(185, 185, 185);
    border-right: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_KurveRGegen{
    border-left: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100% 
}

.BaurmannPohl_KurveRGegen2{
    border-left: 6px solid rgb(185, 185, 185);
    width: 100%;
    height:100% 
}

.BaurmannPohl_Kurve1_Mobile{
    border-top: 1.5px solid rgb(185, 185, 185);
    border-left: 1.5px solid rgb(185, 185, 185);
    width: 100%;
    height:100%  
}

.BaurmannPohl_Kurve1_1_Mobile{
    border-top: 3px solid rgb(185, 185, 185);
    border-left: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100%  
}


.BaurmannPohl_KurveLGegen_Mobile{
    border-right: 1.5px solid rgb(185, 185, 185);
    width: 100%;
    height:100% 
}

.BaurmannPohl_KurveLTopGegen_Mobile{
    border-bottom: 1.5px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_KurveLTopGegen2_Mobile{
    border-bottom: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_Kurve2_Mobile{
    border-bottom: 1.5px solid rgb(185, 185, 185);
    border-left: 1.5px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_Kurve2_2_Mobile{
    border-bottom: 3px solid rgb(185, 185, 185);
    border-left: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_KurveLBottomGegen_Mobile{
    border-top: 1.5px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_KurveLBottomGegen2_Mobile{
    border-top: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_Kurve3_Mobile{
    border-bottom: 1.5px solid rgb(185, 185, 185);
    border-right: 1.5px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
    
}

.BaurmannPohl_Kurve4_Mobile{
    border-top: 1.5px solid rgb(185, 185, 185);
    border-right: 1.5px solid rgb(185, 185, 185);
    width: 100%;
    height:100%
}

.BaurmannPohl_KurveRGegen_Mobile{
    border-left: 1.5px solid rgb(185, 185, 185);
    width: 100%;
    height:100% 
}

.BaurmannPohl_KurveRGegen2_Mobile{
    border-left: 3px solid rgb(185, 185, 185);
    width: 100%;
    height:100% 
}

.BaurmannPohlKomponent{
    border: 3px solid rgb(185, 185, 185);
    background-color: rgb(214, 214, 214);;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BaurmannPohlKomponent:hover{
    cursor: pointer;
    border: 3px solid #3c86e1;
    transition: 1s;
}

.BaurmannPohlKomponent_active{
    border: 3px solid #e1743c;
    background-color: rgb(214, 214, 214);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BaurmannPohlKomponent_Mobile{
    border: 3px solid rgb(185, 185, 185);
    background-color: rgb(214, 214, 214);;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.BaurmannPohlKomponent_Mobile:hover{
    cursor: pointer;
    border: 3px solid #3c86e1;
    transition: 1s;
}

.BaurmannPohlKomponent_Mobile_active{
    border: 3px solid #e1743c;
    background-color: rgb(214, 214, 214);;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.BaurmannPohlKomponentTitel{
    text-align: center;
    font-size: 120%;
    font-weight: 500;
}

.BaurmannPohlKomponentTitel_4K{
    text-align: center;
    font-size: 150%;
    font-weight: 500;
}

.BaurmannPohlKomponentTitel_Medium{
    text-align: center;
    font-size: 95%;
    font-weight: 500;
}

.BaurmannPohlKomponentTitel_Mobile{
    text-align: center;
    font-size: 68%;
    font-weight: 500;
}

.BaurmannPohlKomponentKurztext{
    font-size: 80%;
    margin-top: 1%;
    text-align: center;
}

.BaurmannPohlKomponentKurztext_4K{
    font-size: 120%;
    margin-top: 3%;
    text-align: center;
}

.BaurmannPohlKomponentKurztext_Medium{
    font-size: 60%;
    margin-top: 1%;
    text-align: center;
}

.BaurmannPohlKomponentKurztext_Mobile{
    position: absolute;
    margin-left: -2000vw;
}

.BaurmannPohlExplainField{
    border: 3px solid rgb(185, 185, 185);
    background-color: rgb(214, 214, 214);;
    width: 90%;
    height: 95%;
    display: flex;
    flex-direction: column;
}

.BaurmannPohlExplainField_Mobile{
    border: 3px solid rgb(185, 185, 185);
    background-color: rgb(214, 214, 214);;
    width: 90%;
    height: 95%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.BauermannPohlExplainTitel{
    margin-left: 2%;
    font-size: 150%;
    font-weight: 600;
}

.BauermannPohlExplainTitel_4K{
    margin-left: 2%;
    font-size: 250%;
    font-weight: 600;
    text-align: center;
}

.BauermannPohlExplainTitel_Mobile{
    text-align: center;
    font-size: 110%;
    font-weight: 600;
}

.BaurmannPohlKomponentText{
    margin-left: 2%;
    font-size: 100%;
}

.BaurmannPohlKomponentText_4K{
    margin-left: 2%;
    font-size: 140%;
}

.BaurmannPohlKomponentText_Mobile{
    font-size: 85%;
    margin-left: 2%;
    width: 95%;
}

.BaurmannPohlExplainField_gone{
    position: absolute;
    margin-left: -2000vw;
}