.Modul3Wrapper{
    position: absolute;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: black;
}

.ModulHeader{

    margin-top: 10%;
    color: white;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
}

.ModulHeader_Mobile{

    margin-top: 26%;
    color: white;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}

.ModulHeader_4K{

    margin-top: 10%;
    color: white;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
}

.ModulHeader_Ipad{

    margin-top: 15%;
    color: white;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
}

.Row2{
    width: 60%;
    display: flex;
    flex-direction: row;
}

.ModulHeaderElement{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e0e0e0 65%, #595959);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 220%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.ModulHeaderElement_4K{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e0e0e0 65%, #595959);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 320%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.ModulHeaderElement_Ipad{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e0e0e0 65%, #595959);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 200%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    
}

.ModulHeaderElement_Mobile{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e0e0e0 65%, #595959);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 140%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.ModulHeaderElement_Colored{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 220%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.ModulHeaderElement_Colored_4K{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 320%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.ModulHeaderElement_Colored_Ipad{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 200%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.ModulHeaderElement_Colored_Mobile{
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    font-size: 140%;
    margin-left: 2%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.SlideShow{
    border-top: 1px solid rgb(94, 94, 94);
    border-bottom: 1px solid rgb(94, 94, 94);
    height: 40vh;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
}

.SlideShow_Mobile{
    border-top: 1px solid rgb(94, 94, 94);
    border-bottom: 1px solid rgb(94, 94, 94);
    height: 15vh;
    margin-top: 5%;
    display: flex;
    flex-direction: row;
}

.SlideShowElement0{
    border-right: 1px solid rgb(94, 94, 94);
    width: 15%;
    background-image: linear-gradient(90deg, #e1733c00 35%, #e1743c);
   

}
.SlideShowElement1{
    background-image: url('../material/Rewriting.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
    height: 100%;
    
}

.SlideShowElement2{
    background-image: url('../material/RewriteAI.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 20%;
    height: 100%;
    margin-left: 0.1%;

}

.SlideShowElement3{
    border-left: 1px solid rgb(94, 94, 94);
    width: 15%;
    background-image: linear-gradient(270deg, #e1733c00 35%, #e1743c);
   

}

.ModulBeschreibArea{
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    
}

.ModulBeschreibArea_4K{
    display: flex;
    flex-direction: row;
    margin-top: 2%;
}

.ModulBeschreibArea_Mobile{
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    
}

.ModulBeschreibAreaText{
    color: white;
    margin-left: 5%;
    width: 55%;
    font-size: 110%;
    font-weight: 100;
    text-align: justify;
    hyphens: auto;   
}

.ModulBeschreibAreaText_4K{
    color: white;
    margin-left: 5%;
    width: 55%;
    font-size: 200%;
    font-weight: 100;
    text-align: justify;
    hyphens: auto;   
}

.ModulBeschreibAreaText_Mobile{
    color: white;
    margin-left: 5%;
    width: 90%;
    font-size: 110%;
    font-weight: 100;
    text-align: justify;
    hyphens: auto;   
}

.ModulBeschreibBox{
    border: 2px solid rgb(94, 94, 94);
    height: auto;
    width: 30%;
    margin-left: 2%;
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding-left: 2%;
    font-weight: 800;
    background-color: rgb(188, 188, 188);;
}

.ModulBeschreibBox_4K{
    border: 2px solid rgb(94, 94, 94);
    height: auto;
    width: 30%;
    margin-left: 2%;
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding-left: 2%;
    font-weight: 800;
    font-size: 160%;
    background-color: rgb(188, 188, 188);;
}

.ModulBeschreibBox_Ipad_upright{
    border: 2px solid rgb(94, 94, 94);
    height: auto;
    width: 40%;
    margin-left: 2%;
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding-left: 2%;
    font-weight: 800;
    background-color: rgb(188, 188, 188);
    font-size: 90%;
}

.ModulBeschreibBox_Mobile{
    border: 2px solid rgb(94, 94, 94);
    height: auto;
    width: 83%;
    margin-left: 7%;
    color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding-left: 2%;
    font-weight: 800;
    background-color: rgb(188, 188, 188);;
}

.TermineArea{
    margin-top: 3%;
    margin-left: 5%;
    width: 90%;
    height: auto;
}

.TermineArea_4K{
    margin-top: 3%;
    margin-left: 5%;
    width: 90%;
    height: auto;
    font-size: 150%;
}

.TermineArea_Mobile{
    margin-top: 3%;
    margin-left: 2%;
    width: 90%;
    height: auto;
}

.TermineAreaTitel{
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 180%;
}

.TermineAreaTitel_4K{
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 300%;
}

.TermineAreaTitel_Mobile{
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 140%;
    margin-top: 5%;
    margin-left: 5%;
}

@keyframes SlideIn {
    0% {margin-left: -200vw;}
    100% {margin-left: 0vw}    
}

@keyframes SlideInR {
    0% {margin-left: 800%;}
    100% {margin-left: -5%}    
}