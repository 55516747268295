.AnnotationWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.AnnotationWrapper_Mobile{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.AnnotationTextAreaLevel1{
    border: 2px solid #e1743c;
    width: 70%;
    border-radius: 25px;
    background-color: white;
    padding-top: 2%;  
}

.AnnotationTextAreaLevel1_Mobile{
    border: 2px solid #e1743c;
    width: 100%;
    border-radius: 25px;
    background-color: white;
    padding-top: 2%;  
    height: 60%;
    overflow-y: scroll;
}

.AnnotationTextAreaLevel1_gone{
    position: absolute;
    margin-left: -2000vw;    
}

.AnnotationTextAreaLevel2{
    border: 2px solid #3cbae1;
    width: 70%;
    border-radius: 25px;
    background-color: white;
    padding-top: 2%;
}

.AnnotationTextAreaLevel2_Mobile{
    border: 2px solid #3cbae1;
    width: 100%;
    border-radius: 25px;
    background-color: white;
    padding-top: 2%;  
    height: 60%;
    overflow-y: scroll;
}


.AnnotationTextAreaLevel2_gone{
    position: absolute;
    margin-left: -2000vw;    
}

.AnnotationTextAreaLevel3{
    border: 2px solid #ba3ce1;
    width: 70%;
    border-radius: 25px;
    background-color: white;
    padding-top: 2%;
}

.AnnotationTextAreaLevel3_Mobile{
    border: 2px solid #ba3ce1;
    width: 100%;
    border-radius: 25px;
    background-color: white;
    padding-top: 2%;  
    height: 60%;
    overflow-y: scroll;
}

.AnnotationTextAreaLevel3_gone{
    position: absolute;
    margin-left: -2000vw;    
}

.AnnotationTextAreaLevel4{
    border: 2px solid #4fb438;
    width: 70%;
    border-radius: 25px;
    background-color: white;
    padding-top: 2%;
}

.AnnotationTextAreaLevel4_Mobile{
    border: 2px solid #4fb438;
    width: 100%;
    border-radius: 25px;
    background-color: white;
    padding-top: 2%;  
    height: 60%;
    overflow-y: scroll;
}

.AnnotationTextAreaLevel4_gone{
    position: absolute;
    margin-left: -2000vw;    
}


.AnnotationToolArea{

    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.AnnotationToolArea_Mobile{

    width: 100%;
    height: 40%;
    display: flex;
    margin-top: 2%;
    flex-direction: column;
}

.AnnotationLevelTool{
    width: 100%;
    margin-top: 2%;
    height: 30%;
    display: flex;
    flex-direction: column;
}

.AnnotationLevelTool_Mobile{
    width: 100%;
    margin-top: 2%;
    height: 40%;
    display: flex;
    flex-direction: row;
}

.AnnotationCommentTool{
    width: 100%;
    height: 60%;
    margin-top: 1%;
}

.AnnotationCommentTool_Mobile{
    width: 100%;
    height: 60%;
    margin-top: 2%;
}

.AnnotationLevelToolLevel1_active{
    background-color: #e1743c;
    height:25%;
    text-align: center;
    border-radius: 0px 20px 20px 0px;
    box-shadow:  5px 1px 5px 1px rgb(94, 94, 94);
    z-index: 5;
}

.AnnotationLevelToolLevel1_passive{
    background-color: #bfbfbf;
    height:25%;
    text-align: center;
    border-radius: 0px 20px 20px 0px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 4;
}

.AnnotationLevelToolLevel1_Mobile_active{
    background-color: #e1743c;
    height:100%;
    width: 25%;
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    font-size: 80%;
    z-index: 5;
    padding-top: 3%;
}

.AnnotationLevelToolLevel1_Mobile_passive{
    background-color: #bfbfbf;
    height:100%;
    width: 25%;
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    font-size: 80%;
    z-index: 4;
    padding-top: 3%;
}

.AnnotationLevelToolLevel1_passive:hover{
    cursor: pointer;
}

.AnnotationLevelToolLevel2_passive:hover{
    cursor: pointer;
}

.AnnotationLevelToolLevel3_passive:hover{
    cursor: pointer;
}

.AnnotationLevelToolLevel4_passive:hover{
    cursor: pointer;
}


.AnnotationLevelToolLevel2_active{
    background-color: #3cbae1;;
    height:25%;
    text-align: center;
    border-radius: 0px 20px 20px 0px;
    box-shadow:  5px 1px 5px 1px rgb(94, 94, 94);
    z-index: 5;
}

.AnnotationLevelToolLevel2_passive{
    background-color: #bfbfbf;
    height:25%;
    text-align: center;
    border-radius: 0px 20px 20px 0px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 3;
}

.AnnotationLevelToolLevel2_Mobile_active{
    background-color: #3cbae1;
    height:100%;
    width: 25%;
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    font-size: 80%;
    z-index: 5;
    padding-top: 3%;
}

.AnnotationLevelToolLevel2_Mobile_passive{
    background-color: #bfbfbf;
    height:100%;
    width: 25%;;
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    font-size: 80%;
    z-index: 3;
    padding-top: 3%;
}

.AnnotationLevelToolLevel3_active{
    background-color: #ba3ce1;
    height:25%;
    text-align: center;
    border-radius: 0px 20px 20px 0px;
    box-shadow:  5px 1px 5px 1px rgb(94, 94, 94);
    z-index: 5;
}

.AnnotationLevelToolLevel3_Mobile_active{
    background-color: #ba3ce1;
    height:100%;
    width: 25%;
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    font-size: 80%;
    z-index: 5;
    padding-top: 3%;
}


.AnnotationLevelToolLevel3_passive{
    background-color: #bfbfbf;
    height:25%;
    text-align: center;
    border-radius: 0px 20px 20px 0px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 2;
}

.AnnotationLevelToolLevel3_Mobile_passive{
    background-color: #bfbfbf;
    height:100%;
    width: 25%;
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    font-size: 80%;
    z-index: 2;
    padding-top: 3%;
}

.AnnotationLevelToolLevel4_active{
    background-color: #4fb438;
    height:25%;
    text-align: center;
    border-radius: 0px 20px 20px 0px;
    box-shadow:  5px 1px 5px 1px rgb(94, 94, 94);
    z-index: 5;
}

.AnnotationLevelToolLevel4_Mobile_active{
    background-color: #4fb438;
    height:100%;
    width: 25%;
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    font-size: 80%;
    z-index: 5;
    padding-top: 3%;
}

.AnnotationLevelToolLevel4_passive{
    background-color: #bfbfbf;
    height:25%;
    text-align: center;
    border-radius: 0px 20px 20px 0px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    z-index: 1;
}

.AnnotationLevelToolLevel4_Mobile_passive{
    background-color: #bfbfbf;
    height:100%;
    width: 25%;
    text-align: center;
    border-radius: 20px 20px 20px 20px;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    font-size: 80%;
    z-index: 1;
    padding-top: 3%;
}

.AnnotationTextRow{
    margin-top: 0.5%;
    width: 96%;
    margin-left: 2%;
    display: flex;
    flex-direction: row;
    font-size: 100%;

}

.AnnotationTextRow_Smaller{
    margin-top: 0.5%;
    width: 96%;
    margin-left: 2%;
    display: flex;
    flex-direction: row;
    font-size: 85%;
}

.AnnotationTextRow_Larger{
    margin-top: 0.5%;
    width: 96%;
    margin-left: 2%;
    display: flex;
    flex-direction: row;
    font-size: 120%;
}

.AnnotationItemShell{
    margin-left: 0.6%;
    border-radius: 15px;
}


.AnnotationItemWrapper_Marked{
    border-radius: 15px;
    border: 2px solid #e1743c;
}

.AnnotationItemWrapper_Marked_Level1{
    background-color: #e1743c;
}

.LoschenButton{
    width: 4.5vw;
    height: 4.5vw;
    margin-left: 90%;
    margin-top: 2%;
    border-radius: 100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
}

.LoschenButton_Mobile{
    width: 12vw;
    height: 12vw;
    margin-left: 80%;
    margin-top: 1%;
    border-radius: 100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    margin-bottom: 3%;
}

.LoschenButton:hover{
    border:2px solid #e1743c;
    cursor: pointer;
}

.loschenButtonBild{
    background-image: url('../material/MullButton.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%
}

.CommentToolLevel1{
    background-color: #e1743c;
    width: 100%;
    height: 90%;
    border-radius: 0px 25px 25px 0px;
    display: flex;
    flex-direction: column;
}

.CommentToolLevel1_Mobile{
    background-color: #e1743c;
    width: 100%;
    height: 100%;
    border-radius: 25px 25px 25px 25px;
    display: flex;
    flex-direction: column;
}

.CommentToolLevel1_gone{
    position: absolute;
    margin-left: -2000vw;
}

.CommentToolLevel2_gone{
    position: absolute;
    margin-left: -2000vw;
}

.CommentToolLevel3_gone{
    position: absolute;
    margin-left: -2000vw;
}

.CommentToolLevel4_gone{
    position: absolute;
    margin-left: -2000vw;
}

.CommentToolLevel2{
    background-color: #3cbae1;
    width: 100%;
    height: 90%;
    border-radius: 0px 25px 25px 0px;
    display: flex;
    flex-direction: column;
}

.CommentToolLevel2_Mobile{
    background-color: #3cbae1;
    width: 100%;
    height: 100%;
    border-radius: 25px 25px 25px 25px;
    display: flex;
    flex-direction: column;
}

.CommentToolLevel3{
    background-color: #ba3ce1;
    width: 100%;
    height: 90%;
    border-radius: 0px 25px 25px 0px;
    display: flex;
    flex-direction: column;
}

.CommentToolLevel3_Mobile{
    background-color: #ba3ce1;
    width: 100%;
    height: 100%;
    border-radius: 25px 25px 25px 25px;
    display: flex;
    flex-direction: column;
}

.CommentToolLevel4{
    background-color: #4fb438;
    width: 100%;
    height: 90%;
    border-radius: 0px 25px 25px 0px;
    display: flex;
    flex-direction: column;
}

.CommentToolLevel4_Mobile{
    background-color: #4fb438;
    width: 100%;
    height: 100%;
    border-radius: 25px 25px 25px 25px;
    display: flex;
    flex-direction: column;
}

.CommentToolTitel{
    text-align: center;
    font-size: 150%;
    font-weight: 500;
}

.CommentToolTitel_Mobile{
    text-align: center;
    font-size: 100%;
    font-weight: 500;
}

.CommentToolCommentField{
    margin-top: 3%;
    margin-left: 5%;
    width: 90%;
    height: 75%;
    overflow-y: scroll;
    color: black;
}

.CommentToolCommentField_Mobile{
    margin-top: 3%;
    margin-left: 5%;
    width: 90%;
    height: 70%;
    overflow-y: scroll;
    color: black;
}

.LevelToolLabel{
    font-size: 120%;
}

.LevelToolLabel_Mobile{
    font-size: 90%;
}

.LevelToolLabel_4K{
    font-size: 150%;
}


