.NavWrapper{
    position: absolute;
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    overflow-x: hidden;

}

.NavWrapper_4K{
    position: absolute;
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    overflow-x: hidden;

}

.NavWrapper_Ipad{
    position: absolute;
    width: 100vw;
    height: 15vh;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    margin-top: 0.1%;
}

.NavWrapper_Mobile{
    position: absolute;
    width: 100vw;
    height: 10vh;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    margin-top: 1%;
}

.LogoArea{
    
    width: 20%;
    display: flex;
    flex-direction: row;
    margin-left: 1%;
}

.LogoArea_4K{
    
    width: 20%;
    display: flex;
    flex-direction: row;
    margin-left: 1%;
}

.LogoArea_Ipad{
    
    width: 40%;
    display: flex;
    flex-direction: row;
}

.LogoArea_Ipad_upright{
    
    width: 35%;
    display: flex;
    flex-direction: row;
}

.LogoArea_Mobile{
    
    width: 70%;
    display: flex;
    flex-direction: row;
}

.LogoField{

    width: 30%;
}

.LogoTextField{
    width: 70%;
   
}

.LogoText1{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 110%;
    margin-top: 5%;
    font-weight: 1000;
    color: rgb(225, 225, 225);
}

.LogoText2{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 110%;
    margin-top: 0%;
    font-weight: 500;
    color: rgb(225, 225, 225);
}

.LogoText1_black{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 110%;
    margin-top: 5%;
    font-weight: 1000;
    color: rgb(0, 0, 0);
}

.LogoText2_black{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 110%;
    margin-top: 0%;
    font-weight: 500;
    color: rgb(0, 0, 0);
}

.LogoText1_4K{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 170%;
    margin-top: 7%;
    font-weight: 1000;
    color: rgb(225, 225, 225);
}

.LogoText2_4K{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 150%;
    margin-top: 0%;
    font-weight: 500;
    color: rgb(225, 225, 225);
}

.LogoText1_black_4K{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 170%;
    margin-top: 7%;
    font-weight: 1000;
    color: rgb(0, 0, 0);
}

.LogoText2_black_4K{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 150%;
    margin-top: 0%;
    font-weight: 500;
    color: rgb(0, 0, 0);
}

.LogoText1_Mobile{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 120%;
    margin-top: 5%;
    font-weight: 1000;
    color: rgb(225, 225, 225);
}

.LogoText2_Mobile{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 100%;
    margin-top: 0%;
    font-weight: 500;
    color: rgb(225, 225, 225);
}

.LogoText1_Mobile_black{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 120%;
    margin-top: 5%;
    font-weight: 1000;
    color: rgb(0, 0, 0);
}

.LogoText2_Mobile_black{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 100%;
    margin-top: 0%;
    font-weight: 500;
    color: rgb(0, 0, 0);
}

.LogoText1_Ipad{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 140%;
    margin-top: 10%;
    font-weight: 1000;
    color: rgb(225, 225, 225);
}

.LogoText1_Ipad_upright{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 110%;
    margin-top: 10%;
    font-weight: 1000;
    color: rgb(225, 225, 225);
    margin-left: 4%;
}

.LogoText1_Ipad_black_upright{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 110%;
    margin-top: 10%;
    font-weight: 1000;
    color: rgb(0, 0, 0);
    margin-left: 4%;
}

.LogoText2_Ipad_black{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 120%;
    margin-top: 0%;
    font-weight: 500;
    color: rgb(0, 0, 0);
}

.LogoText1_Ipad_black{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 140%;
    margin-top: 10%;
    font-weight: 1000;
    color: rgb(0, 0, 0);
}

.LogoText2_Ipad{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 120%;
    margin-top: 0%;
    font-weight: 500;
    color: rgb(225, 225, 225);
}

.LogoText2_Ipad_upright{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 110%;
    margin-top: 0%;
    font-weight: 500;
    margin-left: 5%;
    color: rgb(225, 225, 225);
}

.LogoText2_Ipad_black_upright{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 110%;
    margin-top: 0%;
    font-weight: 500;
    margin-left: 5%;
    color: rgb(0, 0, 0);
}

.SchillerLogo{
    background-image: url('../material/SchreibenmitKILogo_MK3.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    margin-left: 5%;
}

.SchillerLogo_Black{
    background-image: url('../material/SchreibenmitKILogo_black.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    margin-left: 5%;
}

.NavigationArea{

    position: absolute;
    Right: 0.01%;
    height: 10vh;
    width: 40%;
    
}

.NavigationArea_4K{

    position: absolute;
    Right: 0.01%;
    height: 10vh;
    width: 40%;
    
}

.NavigationArea_Ipad{

    position: absolute;
    Right: 0%;
    height: 14.5vh;
    width: 50%;
}

.NavigationArea_Ipad_upright{

    position: absolute;
    Right: 0%;
    height: 9vh;
    width: 50%;
}

.NavigationArea_Mobile{

    width: 30%;
    Right: 0%;
    height: 100%;
}

.NavBar{
    background-color: #011324;
    border:0px solid rgb(116, 116, 116);
    height: 60%;
    border-radius: 0 0 00px 30px;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: row;

}

.NavBar_gone{
    background-color: blue;
    position: absolute;
    margin-left: -5000vw;
}

.MobileNav_gone{
    position: absolute;
    margin-left: -5000vw;
}

.MobileNav{
    color:rgb(225, 225, 225);
    font-size: 200%;
    text-align: center;
    margin-top: 15%;
    border: 2px solid rgb(225, 225, 225);
    width: 50%;
    justify-self: center;
    align-self: center;
    margin-left: 30%;
    border-radius: 20px;
}

.NavBarElement{
    color: rgb(225, 225, 225);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 2%;
    margin-left: 5%;
    font-size: 110%;
}

.NavBarElement_4K{
    color: rgb(225, 225, 225);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 2%;
    margin-left: 5%;
    font-size: 160%;
}

.NavBarElement:hover{
    cursor: pointer;
}

.NavBarElement_4K:hover{
    cursor: pointer;
}

.NavBarElement_Ipad{
    color: rgb(225, 225, 225);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-top: 4%;
    font-size: 100%;
    margin-left: 5%;
}

.MobileNavScreen{
    position: fixed;
    width: 100vw;
    height: 100%;
    Top: 0%;
    Left:0%;
    background-color: rgb(0, 0, 0);
    z-index: 999;;
}

.MobileNavScreen_gone{
    position: absolute;
    margin-left: -5000vw;
}

.MobileNavScreenTitel{
    margin-top: 2%;
    text-align: center;
    font-size: 200%;
    font-weight: 1000;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}

.MobileNavScreenElement1{
    color: rgb(225, 225, 225);
    text-align: center;
    font-size: 150%;
    margin-top: 40%;
    padding-top: 4%;
    font-weight: 500;
    padding-bottom: 4%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-bottom: 1px solid rgb(174, 111, 43);
    border-top: 1px solid rgb(174, 111, 43);
    width: 80%;
    margin-left: 10%;
}

.MobileNavScreenElement2{
    color: rgb(225, 225, 225);
    text-align: center;
    font-size: 150%;
    margin-top: 1%;
    padding-top: 4%;
    font-weight: 500;
    padding-bottom: 4%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-bottom: 1px solid rgb(174, 111, 43);
    width: 80%;
    margin-left: 10%;
}

.MobileNavClose{
    color: rgb(174, 111, 43);
    border: 2px solid rgb(174, 111, 43);
    width: 10%;
    border-radius: 15px;
    text-align: center;
    font-size: 200%;
    position: absolute;
    Top: 1%;
    Left: 1%
}


.LoginOverLay{
    position: fixed;
    background-color: #011324;
    width: 20%;
    height: 20%;
    Top: 5%;
    Right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border-radius: 0px 0px 15px 15px;
}

.LoginOverLay_error{
    position: fixed;
    background-color: #a20000;
    width: 20%;
    height: 20%;
    Top: 5%;
    Right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    border-radius: 0px 0px 15px 15px;
}

.LoginOverlayRow{
    width: 80%;
    height: 25%;
}

.LoginOverLay_gone{
    position: absolute;
    margin-left: -2000vw;
}

.LoginOverLayButton{
    background-color: rgb(174, 111, 43);
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 25px;
    font-size: 130%;
    font-weight: 600;
}

.LoginOverLayButton:hover{
    cursor: pointer;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
}
.LoginOverLayInput{
    background-color: rgb(239, 239, 239);
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 120%;
    border-radius: 5px;
}

.LoginOverLayButton_gone{
    position: absolute;
    margin-left: -2000vw;
}

.LoginOverlayMessage{
    text-align: center;
    color: white;
    font-size: 130%;
    margin-top: 5%;
    font-weight: 800;
}

.LoginOverlayMessage_gone{
   position: absolute;
   margin-left: -2000vw;
   
}

.MobileLoginField{
    font-size: 90%;
    text-align: center;
}