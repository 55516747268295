.FooterWrapper_Landingpage{
    width: 100vw;
    margin-top: -15vh;
    height: 20vh;
    background-color: #001c37;
}

.FooterWrapper_Landingpage_4K{
    width: 100vw;
    margin-top: 10vh;
    height: 10vh;
    background-color: #001c37;
}

.FooterWrapper_Page{
    width: 100vw;
    height: 20vh;
    background-color: #001c37;
    margin-top: 5vh;
}

.FooterWrapper_Landingpage_Ipad{
    width: 100vw;
    margin-top: -25vh;
    height: 20vh;
    background-color: #001c37;
}

.FooterWrapper_Page_Ipad{
    width: 100vw;
    margin-top: 5vh;
    height: 20vh;
    background-color: #001c37;
}

.FooterWrapper_Landingpage_Mobile{
    width: 100vw;
    margin-top: -25vh;
    height: 20vh;
    background-color: #001c37;
    border: 1px solid #001c37;
}

.FooterWrapper_Page_Mobile{
    width: 100vw;
    margin-top: 5vh;
    height: 20vh;
    background-color: #001c37;
    border: 1px solid #001c37;
}

.FooterTextRow{
    position: absolute;
    bottom: 0%;
    width: 90%;
    margin-left: 5%;
    height: auto;
    text-align: center;
    justify-content: center;
    Bottom: 2%;
}

.FooterTextRow_4K{
    position: absolute;
    bottom: 0%;
    width: 90%;
    margin-left: 5%;
    height: auto;
    text-align: center;
    justify-content: center;
    Bottom: 2%;
}


.FooterTextRow_Ipad{
    position: absolute;
    bottom: 1%;
    width: 90%;
    margin-left: 5%;
    text-align: center;
}

.FooterTextRow_Mobile{
    margin-top: 25%;
    width: 80%;
    margin-left: 10%;
    text-align: center;
}

.FooterText{
    color: rgb(201, 199, 199);
    font-weight: 300;
    margin-top: 0%;
}

.FooterText_4K{
    color: rgb(201, 199, 199);
    font-weight: 300;
    margin-top: 0%;
    font-size: 160%;
}

.FooterText_Ipad{
    color: rgb(201, 199, 199);
    font-weight: 300;
    margin-top: -2.5%;
}

.FooterText_Mobile{
    color: rgb(201, 199, 199);
    font-weight: 300;
    margin-top: 0%;
    font-size: 75%;
    margin-bottom: 2%;
}