.RegisterWrapper{
    width: 100vw;
    height: auto;
    background-color: #011324;
    display: flex;
}

.RegisterBody{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 6%;
    
}

.RegisterHead{
    padding: 3vw;
}

.RegisterHead_Mobile{
    padding: 3vw;
    margin-top: 15%;
}


.RegisterTitel{
    font-size: 250%;
    font-weight: 500;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.RegisterTitel_Mobile{
    font-size: 120%;
    font-weight: 500;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.RegisterMain{
    display: flex;
    justify-content: center;
}

.RegisterContainer{

    width: 80vw;
    height: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom:3vh
}

.RegisterContainer_Mobile{

    width: 95%;
    height: auto;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom:3vh
}

.RegisterRow{
    width: 100%;
    height: 10vh;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    color: rgb(235, 235, 235);
}

.RegisterRow_Mobile{
    width: 100%;
    height: 10vh;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    color: rgb(235, 235, 235);
}

.RegisterRow2{
    width: 100%;
    height: 10vh;
    margin-top: 3dvb;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5%;
    color: rgb(235, 235, 235);
    
}

.RegisterRow2_Mobile{
    width: 100%;
    height: 10vh;
    margin-top: 3dvb;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5%;
    color: rgb(235, 235, 235);
    
}

.RegisterLabel{
    margin-left: 5%;
    font-size: 190%;
    width: 16vw;
    padding-top: 1.3%;
    color: rgb(235, 235, 235);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.RegisterLabel_Mobile{
    margin-left: 5%;
    font-size: 100%;
    width: 35%;
    padding-top: 1.3%;
    color: rgb(235, 235, 235);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.RegisterInput1{
    font-size: 190%;
    height:70%;
    margin-top: 1%;
    width: 30vw;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.RegisterInput1_Mobile{
    font-size: 110%;
    height:70%;
    margin-top: 1%;
    width: 50%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.RegisterInput2{
    font-size: 190%;
    height:70%;
    margin-top: 1%;
    width: 30vw;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.RegisterInput2_Fix{
    font-size: 190%;
    height:70%;
    margin-top: 1%;
    width: 30vw;
    color:rgb(235, 235, 235);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.RegisterInput2_Fix_gone{
    position: absolute;
    margin-left: -3000vw;
}

.RegisterInput2_gone{
    position: absolute;
    margin-left: -3000vw;
}
.RegisterButton{
    border: 1px solid black;
    width: 20vw;
    height: 80%;
    margin-top: 1%;
    text-align: center;
    font-size: 200%;
    padding-top: 0.4%;
    border-radius: 5px;
    background-color: #e1743c;
    box-shadow: 0.1vw 0.1vw 0.5vw black; 
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
}

.RegisterButton_Mobile{
    border: 1px solid black;
    width: 50%;
    height: 80%;
    margin-top: 1%;
    text-align: center;
    font-size: 140%;
    padding-top: 0.4%;
    border-radius: 5px;
    background-color: #e1743c;
    box-shadow: 0.1vw 0.1vw 0.5vw black; 
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
    font-weight: 800;
}

.RegisterButton_gone{
    position: absolute;
    margin-left: -3000vw;
}

.RegisterButton:hover{
    background-color: #e49e7b;
    cursor: pointer;
    transition: 1s;
}

.RegisterChecker{
    font-size: 3vw;
    margin-left: 2vw;
}

.RegisterHelpBut{
    border: 1px solid black;
    width: 10vw;
    height: 60%;
    margin-top: 1%;
    text-align: center;
    font-size: 130%;
    padding-top: 0.6%;
    border-radius: 5px;
    background-color: #e1743c;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
}

.RegisterHelpBut_Mobile{
    border: 1px solid black;
    width: 20%;
    height: 60%;
    margin-top: 1%;
    text-align: center;
    font-size: 70%;
    padding-top: 0.6%;
    border-radius: 5px;
    background-color: #e1743c;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: black;
}

.RegisterHelpBut:hover{
    cursor: pointer;
}

.RegisterHelpBut_gone{
   position: absolute;
   margin-left: -3000vw;
}
