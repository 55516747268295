.AdminPageWrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.AdminPageBody{
    width: 80%;
    margin-left: 10%;
    margin-top: 5%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.AdminPageButton{
    margin-top: 2%;
    background-color: #e1743c;
    border-radius: 25px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: 500;
    margin-bottom: 1%;
    font-size: 150%;
}

.AdminPageButton_Mobile{
    margin-top: 5%;
    background-color: #e1743c;
    border-radius: 25px;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1%;
    padding-bottom: 1%;
    font-weight: 500;
    font-size: 110%;
}

.AdminPageButton:hover{
    cursor: pointer;
}

.AdminPageButton_gone{
    position: absolute;
    margin-left: -2000vw;
}

.AdminPageKursSelector{
    border: 1px solid black;
    width: 80%;
    height: 20vh;
    display: flex;
    flex-direction: column;
}

.AdminPage_Select_exist{
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.AdminPage_Field{
    font-size: 150%;
    text-align: center;
    margin-right: 2%;
}

.AdminPage_Field_Mobile{
    font-size: 130%;
    text-align: center;
    margin-right: 5%;
    width: 50%;
}

.AdminPage_Field_success{
    font-size: 150%;
    text-align: center;
    margin-right: 2%;
    background-color: greenyellow;
}

.AdminPage_Field_success_Mobile{
    font-size: 150%;
    text-align: center;
    margin-right: 2%;
    background-color: greenyellow;
    width: 50%;
}

.AdminPageNeuerKurs{
    border: 1px solid black;
    width: 6vw;
    height: 3vw;
    text-align: center;
    margin-left: 5%;
    font-size: 200%;
}

.AdminPageNeuerKurs_Mobile{
    border: 1px solid black;
    width: 10vw;
    height: 10vw;
    text-align: center;
    margin-left: 5%;
    font-size: 200%;
}

.AdminPageBox{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    margin-top: 2%;
}

.AdminPageBoxColumn{
    width: 40%;
    height: auto;
    margin-left: 5%;
}