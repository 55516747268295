.FachTagWrapper{
    position: absolute;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: black;
}

.FachTagBody{
    width: 100%;
    height: auto;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.FachTagTitelField{
    color:white;
    margin-top: 15vh;
    width: 80%;
    font-size: 5vh;
    font-weight: 500;
    height: auto;
    margin-left: 10%;
}

.FachTagTitelField_large{
    color:white;
    margin-top: 10vh;
    width: 80%;
    font-size: 5vh;
    font-weight: 500;
    height: auto;
    margin-left: 10%;
}

.FachTagTitelField_Mobile{
    color:white;
    margin-top: 12vh;
    width: 90%;
    font-size: 4vh;
    font-weight: 500;
    height: auto;
    margin-left: 5%;
}

.FachtagTitel2{
    font-size: 3vh;
    color: rgb(204, 204, 204);
}

.FachtagTitel2_large{
    font-size: 3vh;
    color: rgb(204, 204, 204);
}

.FachtagTitel2_Mobile{
    font-size: 2vh;
    color: rgb(204, 204, 204);
}


.FynnTextArea{
    background-color: white;
    width: 80%;
    height: auto;
    margin-left: 10%;
    margin-top: 3%;
    font-size: 3vh;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.FynnTextArea_large{
    background-color: white;
    width: 80%;
    height: auto;
    margin-left: 10%;
    margin-top: 3%;
    font-size: 3vh;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.FynnTextArea_Mobile{
    background-color: white;
    width: 90%;
    height: auto;
    margin-left: 5%;
    margin-top: 3%;
    font-size: 1.8vh;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.FachTagButtonArea{
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.FachTagButtonArea_Mobile{
    width: 100%;
    height: 10vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.FachTagCopyButton{
    width: 25%;
    padding-top: 1%;
    padding-bottom: 1%;
    height: auto;
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #e1743c 65%, rgb(106, 23, 23));
    border-radius: 25px;
    text-align: center;
    font-size: 4vh;
    font-weight: 800;
}

.FachTagCopyButton_Mobile{
    position: absolute;
    margin-left: -5000vw;
}

.FachTagCopyButton:hover{
    cursor: pointer;
    width: 25%;
    padding-top: 1%;
    padding-bottom: 1%;
    height: auto;
    background-color: #f3ec78;
    background-image: linear-gradient(180deg, #ff8345 65%, rgb(197, 41, 41));
    border-radius: 25px;
    text-align: center;
    font-size: 4vh;
    font-weight: 800;
}