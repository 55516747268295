.NavImage_Gears{
    background-image: url('../../material/Gears.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Gears:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_Gears_active{
    background-image: url('../../material/Gears.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .NavImage_Connor{
    background-image: url('../../material/Button_Connor.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Connor:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_Connor_active{
    background-image: url('../../material/Button_Connor.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .NavImage_Danielle{
    background-image: url('../../material/Danielle.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Danielle:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_Danielle_active{
    background-image: url('../../material/Danielle.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .NavImage_Luca{
    background-image: url('../../material/Luka.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Luca:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_Luca_active{
    background-image: url('../../material/Luka.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .NavImage_Miles{
    background-image: url('../../material/Miles.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
 }

 .NavImage_Miles:hover{
    background-image: none;
    background-color: #e1743c;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height:100%;
    box-shadow:  1px 1px 5px 1px rgb(94, 94, 94);
    border-radius: 100%;
    cursor: pointer;
    transition: 1s;
 }

 .NavImage_Miles_active{
    background-image: url('../../material/Miles.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:100%;
    box-shadow:  1px 2px 8px 2px #e1743c;
    border-radius: 100%;
 }

 .LerndendenBlock{
    border: 1px solid pink;
    width: 100%;
    height: 40%;
 }

 .Lernende2{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 95%;
    display: flex;
    flex-direction: column;
    
}

.Lernende2_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 10%;
    height: 82%;
    display: flex;
    flex-direction: column;
    
}

.Lernende2Titel{
    text-align: center;
    font-size: 200%;
    font-weight: 800;
    margin-top: -2%;
}

.Lernende2Titel_Mobile{
    text-align: center;
    font-size: 150%;
    font-weight: 800;
}

.Lernende2Area{
    width: 100%;
    height: 85%;
    margin-top: 1%;
    display: flex;
    flex-direction: column;
}

.Lernende2Area_Mobile{
    width: 100%;
    height: 91%;
    margin-top: 6%;
    display: flex;
    flex-direction: column;
}

.LernendeRow{
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.LernendeRow_Mobile{
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: center;
    align-items: center;
}

.Lernenden2Box{
    background-color: rgb(164, 164, 164);
    width: 40%;
    height: 80%;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
}

.Lernenden2Box_Mobile{
    background-color: rgb(164, 164, 164);
    width: 80%;
    height: 45%;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
}

.Lernenden2PictureArea{
    height: 100%;
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.Lernenden2InfoArea{
    height: 100%;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: -5%;
}

.Lernenden2ImageBox{
    width: 10vw;
    height: 10vw;
}

.Lernenden2ImageBox_Mobile{
    width: 18vw;
    height: 18vw;
}

.Lerner_Connor{
    width: 100%;
    height: 100%;
    background-image: url('../../material/Button_Connor.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
}

.Lerner_Danielle{
    width: 100%;
    height: 100%;
    background-image: url('../../material/Danielle.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.Lerner_Luca{
    width: 100%;
    height: 100%;
    background-image: url('../../material/Luka.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.Lerner_Miles{
    width: 100%;
    height: 100%;
    background-image: url('../../material/Miles.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.Lernenden2InfoRow{
    width: 90%;
    text-align: left;
    font-size: 110%;
    margin-top: 2%;
}

.Lernenden2InfoRow_Mobile{
    width: 95%;
    text-align: left;
    font-size: 62%;
    margin-top: 2%;
}


.AufgabenErgebnisArea{
    width: 100%;
    height: 85%;
    margin-top: 1%;
    display: flex;
    flex-direction: row;
}

.AufgabenErgebnisArea_Mobile{
    width: 100%;
    height: 91%;
    margin-top: 6%;
    display: flex;
    flex-direction: column;
}

.AufgabenErgebnisBox{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.AufgabenErgebnisBox_Mobile{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.Lernenden2Schulbuchseite{
    width: 90%;
    height: 95%;
    background-color: rgb(243, 243, 239);
    box-shadow: 1px 1px 5px 1px rgb(87, 87, 87);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}


.Lernenden2Schulbuchseite_Mobile{
    width: 99%;
    height: 95%;
    background-color: rgb(243, 243, 239);
    box-shadow: 1px 1px 5px 1px rgb(87, 87, 87);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.Lernenden2SchulbuchseiteTitel{
    width: 90%;
    margin-top: 2%;
    font-size: 120%;
}

.Lernenden2SchulbuchseiteTitel_Mobile{
    width: 90%;
    margin-top: 2%;
    font-size: 90%;
    overflow-y: scroll;
}

.EnglischBox{
    background-color: rgb(220, 220, 220);
    width: 90%;
    height: 20%;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
}

.EnglischBox_Mobile{
    background-color: rgb(220, 220, 220);
    width: 90%;
    height: 42%;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
}

.EnglischBoxColumn{
    width: 33%;
    font-size: 80%;
    padding-left: 2%;
    margin-left: 1%;
}

.EnglischBoxColumn_Mobile{
    width: 33%;
    font-size: 20%;
    padding-left: 2%;
    margin-left: 1%;
}

.AufgabeTextRow{
    font-size: 82%;
    width: 90%;
    margin-top: 2%;
}

.AufgabeTextRow_Mobile{
    font-size: 72%;
    width: 90%;
    margin-top: 2%;
}

.AufgabeTextRow2{
    font-size: 82%;
    width: 90%;
    margin-top: 0%;   
}

.AufgabeBildReihe{
    width: 90%;
    margin-top: 2%;
    height: 35%;
    display: flex;
}

.AufgabeBildReihe2{
    width: 68%;
    margin-top: 2%;
    height: 10vh;
    display: flex;
}

.AufgabeBildReihe_Mobile{
    width: 68%;
    margin-top: 2%;
    height: 10vh;
    display: flex;
}

.Aufgabenbild_Religion{
    width: 100%;
    height: 100%;
    background-image: url('../../material/Religion.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.HistoColumns{
    width: 90%;
    height: auto;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
}

.HistoColumns_Mobile{
    width: 90%;
    height: auto;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    font-size: 80%;
}

.HistoColumnTextblock{
    width: 55%;
    height: 100%;
    text-align: justify;
    margin-right: 1%;
}

.HistoColumnBildblock{
    width: 45%;
    height: 100%;
    display: flex;
}

.Geschichte_Bild1{
    width: 100%;
    height: 100%;
    background-image: url('../../material/Geschichte1.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.Geschichte_Bild2{
    width: 100%;
    height: 20vh;
    background-image: url('../../material/Geschichte2.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.Erdbeere_Bild1{
    width: 100%;
    height: 100%;
    background-image: url('../../material/Erdbeere0.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.Erdbeere_Bild2{
    width: 100%;
    height: 100%;
    background-image: url('../../material/Erdbeere4.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.Erdbeere_Bild3{
    width: 100%;
    height: 100%;
    background-image: url('../../material/Erdbeere1.png');
    background-repeat: no-repeat;
    background-size: contain;
}

.Erdbeere_Bild4{
    width: 100%;
    height: 100%;
    background-image: url('../../material/Erdbeere2.png');
    background-repeat: no-repeat;
    background-size: contain;
}




.HausaufgabenField{
    margin-left: 2%;
    width: 95%;
    margin-top: 2%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow-y: scroll;
}

.HausaufgabenField_Mobile{
    margin-left: 5%;
    width: 90%;
    margin-top: 10%;
    height: 92%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    overflow-y: scroll;
    
}

.HausaufgabenText_4K{
    width: 75%;
    height: auto;
    font-size: 150%;
}

.HausaufgabenText{
    width: 75%;
    height: auto;
    font-size: 100%;
}

.HausaufgabenText_Mobile{
    width: 95%;
    height: auto;
    font-size: 78%;
    
}