.ProjektseitenWrapper{
    position: absolute;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
}

.ProjektHeader{
    
    margin-top: 15%;
    height: 65vh;
    display: flex;
    flex-direction: row;
}

.ProjektHeader_Ipad{
  
    margin-top: 15%;
    height: 52vh;
    display: flex;
    flex-direction: row;
}

.ProjektHeader_Mobile{
    margin-top: 35%;
    height: 35vh;
    display: flex;
    flex-direction: row;
    
}

.PH_Textarea{
    width: 50%;
    height: auto;
}

.PH_Imagearea{
    width: 50%;
    height: 100%;
}

.PH_Image1{
    background-image: url('../material/Schreiben_mit_KI_Lehrerin.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.PH_Image1_Ipad{
    background-image: url('../material/Schreiben_mit_KI_Lehrerin_Ipad.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.PH_Image1_Mobile{
    background-image: url('../material/Schreiben_mit_KI_Lehrerin_Mobile.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.SuperTitel_4K{
    margin-left: 5%;
    font-size: 130%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.SuperTitel{
    margin-left: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}


.SuperTitel_Ipad{
    margin-left: 4%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.SuperTitel_Mobile{
    margin-left: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.PH_TextArea_Titel_4K{
    margin-top: 2%;
    margin-left: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 350%;
    width: 80%;
    font-weight: 600;
}

.PH_TextArea_Titel{
    margin-top: 2%;
    margin-left: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 270%;
    width: 80%;
    font-weight: 600;
}

.PH_TextArea_Titel_Ipad{
    margin-top: 2%;
    margin-left: 4%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 200%;
    width: 80%;
    font-weight: 500;
}

.PH_TextArea_Titel_Mobile{
    margin-top: 2%;
    margin-left: 4%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 100%;
    width: 70%;
    font-weight: 600;
}

.PH_Subtitel_4K{
    margin-top: 8%;
    margin-left: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 190%;;
}

.PH_Subtitel{
    margin-top: 8%;
    margin-left: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 130%;;
}

.PH_Subtitel_Ipad{
    margin-top: 8%;
    margin-left: 4%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 120%;;
}

.PH_Subtitel_Mobile{
    margin-top: 10%;
    margin-left: 5%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 65%;;
}

.Projekt_MainArea_4K{
    border-top: 2px solid rgb(99, 99, 99);
    margin-top: 5%;
    width: 96%;
    margin-left: 2%;
    height: auto;
}

.Projekt_MainArea{
    border-top: 2px solid rgb(99, 99, 99);
    margin-top: 10%;
    width: 96%;
    margin-left: 2%;
    height: auto;
}

.Projekt_MainArea_Ipad{
    border-top: 2px solid rgb(99, 99, 99);
    margin-top: 2%;
    width: 96%;
    margin-left: 2%;
    height: auto;
}

.Projekt_MainArea_Mobile{
    border-top: 1px solid rgb(99, 99, 99);
    margin-top: 2%;
    width: 98%;
    margin-left: 1%;
    height: auto;
}

.ProjektText_4K{
    width: 90%;
    margin-left: 5%;
    font-size: 180%;
    margin-top: 2%;
    text-align: justify;
    hyphens: auto;
}

.ProjektText{
    width: 90%;
    margin-left: 5%;
    font-size: 130%;
    margin-top: 2%;
    text-align: justify;
    hyphens: auto;
}

.ProjektText_Mobile{
    width: 90%;
    margin-left: 5%;
    font-size: 100%;
    margin-top: 5%;
    text-align: justify;
    hyphens: auto;
}

.ProjektText2_4K{
    width: 90%;
    margin-left: 5%;
    font-size: 180%;
    margin-top: 1%;
    text-align: justify;
    hyphens: auto;
}

.ProjektText2{
    width: 90%;
    margin-left: 5%;
    font-size: 130%;
    margin-top: 1%;
    text-align: justify;
    hyphens: auto;
}

.ProjektText2_Mobile{
    width: 90%;
    margin-left: 5%;
    font-size: 100%;
    margin-top: 5%;
    text-align: justify;
    hyphens: auto;
}