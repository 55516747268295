.MullerFurstenberg_Wrapper{
    width: 100%;
    height: 100%;
}

.MullerFurstenberg_Wrapper_Mobile{
    width: 100%;
    height: 95%;
    overflow-y: scroll;
}
.MF_TextArea{
    margin-left: 2%;
    margin-top: 2%;
    width: 90%;
    height: 99%;
    overflow-y: scroll;
}

.MF_TextArea_Mobile{
    margin-left: 2%;
    margin-top: 2%;
    width: 95%;
    height: 99%;
}

.Modul1_Logo{
    
}